import React,{useContext, useState, useEffect} from 'react'
import styled from "styled-components";
import DataCards from '../components/data-cards.components';
import infoIcon from "../assets/images/info.png"
import { C2BLeadsContext, 
        C2BOnlineAppointmentsContext, 
        C2BInspectionsContext,
        C2BPurchasesContext,  
        C2BCurrentUserJourneyContext,
        ClicksC2BContext,
        C2BCommissionContext,
      } from '../context/user.context';
import WholesaleCurrentUserJourney from '../components/wholesale-current-user-journey.component';
import { getAuth } from "firebase/auth";
import { 
  onUserC2BClicksListener,
  onUserC2BLeadsListener,
  onUserC2BOnlineAppointmentsListener,
  onUserC2BInspectionsListener,
  onUserC2BPurchasesListener,
  onUserC2BWholesaleCurrentUserListListener,

} from "../utils/firebase.utils";
import alertIcon from "../assets/images/alert.png"

const Styles = styled.div`
  padding-top:1rem;
  &>.container{
    .data-card-cont{
      display:flex;
      flex-wrap:nowrap;
      justify-content start;
      margin-bottom:1rem;
      .data-card{
        margin-right:1rem;
        margin-bottom:1rem;
        width:25%;
        &:last-of-type{
          margin-right:0;
        }
      }
    }
  .section-2{
    display:flex;
    align-items:start;
    margin-bottom:2rem;
    justify-content: center;
    .table-cont{
      width:70%;
      margin-right:1rem;
    }
    .link-creator{
      width:50%;
    }
  }
  }
  .kpi-header{
    margin-bottom: 10px;
    font-weight: 500;
  }

  .inline-block{
    display: inline-block;
  }

  .info-icon{
    position: relative;
    display: inline-block;
    top: 6px;
    left: 10px;
  }

  .hover-text{
    position: absolute;
    top: 20px;
    left: 70%;
    background-color: white;
    border: 1px solid #E0E0E0;
    border-radius: 2px;
    padding: 10px 15px;
    font-weight: 400;
    font-size: 15px;
    width: max-content;
    line-height: 1.7;
    box-shadow: 0px 2px 2px 0px #00000040;
  }
  .warning-txt{
    float: right;
    font-size: 12px;
    background-color: #D9D9D9;
    padding: 5px 10px 5px 10px;
    border-radius: 20px;
    color: #00205B;
    font-weight: 600;
  }

  .alert-img{
    vertical-align: middle;
    height: 25px;
    padding-right: 7px;
  }

  @media screen and (max-width:1000px){
    &>.container{
      .data-card-cont{
        justify-content:start;
        margin-bottom:0;
        .data-card{
          margin-bottom:1rem;
          margin-right:1rem;
        }
      }
    }
  }
  @media screen and (max-width:850px){
    &>.container{
      .data-card-cont{
        margin-bottom:0;

        flex-wrap:wrap;
        .data-card{
          margin-bottom:1rem;
          &:nth-of-type(even){
            margin-left:10px;
            margin-right:0;
            width: calc( 50% - 10px );
          }
          &:nth-of-type(odd){
            margin-right:10px;
            margin-left:0;
            width: calc( 50% - 10px );
          }
        }
      }
    }
  }
  @media screen and (max-width:600px){
    &>.container{
      .data-card-cont{
        margin-bottom:0;
        justify-content:space-between;
        .data-card{
          margin-bottom:1rem;
          margin-left:0;
          margin-right:0;
        }
      }
      .section-2{
        flex-direction:column-reverse;
        .table-cont{
          width:100%;
          margin-right:0rem;
        }
        .link-creator{
          width:100%;
          margin-left:0rem;
          margin-bottom:1.5rem;
        }
      }
    }
  }
  @media screen and (max-width:410px){
    &>.container{
      .data-card-cont{
        margin-bottom:0;
        flex-direction:column;
        flex-wrap:nowrap;
        justify-content:start;
        align-items:stretch;
        .data-card{
          margin-bottom:1rem;
          margin-left:0!important;
          margin-right:0!important;
          width:100%!important;
          text-align:Center;
        }
      }
  }
`;


const Wholesale = () => {
  const {setUserC2BClicks} = useContext(ClicksC2BContext);
  const {userC2BLeads, setUserC2BLeads} = useContext(C2BLeadsContext);
  const {userC2BOnlineAppointments, setUserC2BOnlineAppointments} = useContext(C2BOnlineAppointmentsContext);
  const {userC2BPurchases, setUserC2BPurchases} = useContext(C2BPurchasesContext);
  const {userC2BInspections, setUserC2BInspections} = useContext(C2BInspectionsContext);
  const {userC2BCurrentUserJourney, setUserC2BCurrentUserJourney} = useContext(C2BCurrentUserJourneyContext);
  const {setUserC2BCommissions} = useContext(C2BCommissionContext);


  const [isC2BHovering, setIsC2BHovering] = useState(false);
  const [isWholesaleLoading, setIsWholesaleLoading] = useState(true);

  const showHideContent = () =>{
    const test = !isC2BHovering
    setIsC2BHovering(test);
  }
  const getWholesaleDetailsByUid = ()=>{
    const auth = getAuth();
    const user = auth.currentUser;
  
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      
      // Wholesale - C2B
      onUserC2BClicksListener(user.uid, (response)=>{
          setUserC2BClicks(response)
      })

      onUserC2BLeadsListener(user.uid, (res)=>{
          setUserC2BLeads(res)
      })

      onUserC2BOnlineAppointmentsListener(user.uid, (res)=>{
          setUserC2BOnlineAppointments(res)
      })

      onUserC2BInspectionsListener(user.uid, (res)=>{
          setUserC2BInspections(res)
      })

      onUserC2BPurchasesListener(user.uid, (res)=>{
          setUserC2BPurchases(res)
      })

      onUserC2BWholesaleCurrentUserListListener(user.uid, (req)=>{
          setUserC2BCurrentUserJourney(req)
          setIsWholesaleLoading(false)
      })     
   }
}
    useEffect(() => {
      getWholesaleDetailsByUid()
    },[])


  return (
    <Styles>
      <div className='container'>
        
        <div>
          <p className='kpi-header inline-block'>Sell Car</p>

          <div className='info-icon'>
            <div onClick={showHideContent}>
              <img src={infoIcon} alt="Info icon"/>
            </div>
            {isC2BHovering &&
            <div className='hover-text'>
              <p><b>Leads:</b> When the user submits the "sell car" form.</p>
              <p><b>Online Appointment:</b> When the user schedules an appointment after selecting location & time slot.</p>
              <p><b>Inspection:</b> When the user completes the inspection.</p>
              <p><b>Purchase Buy-out:</b> When the user accepts the buy-out offer and sells the car. (Not valid for bidding)</p>
            </div>
            }

          </div>
          
          <div className='warning-txt'>
            <img className="alert-img" src={alertIcon} alt="Alert icon"/>
            Inspection & Purchase data will be updated on or before 15th of each month.
          </div>
        </div>

        <div className='data-card-cont'>
            <DataCards name={"Lead"} value={userC2BLeads}/>
            <DataCards name={"Online Appointment"} value={userC2BOnlineAppointments}/>
            <DataCards name={"Inspection"} value={userC2BInspections}/>
            <DataCards name={"Purchase"} value={userC2BPurchases}/>
        </div>

        <div id="current-wholesale-user-journey">
          <WholesaleCurrentUserJourney 
            data={userC2BCurrentUserJourney}
            isLoading={isWholesaleLoading}
          />
        </div>

      </div>
    </Styles>
  )
}

export default Wholesale