import React,{useContext, useState, useEffect} from 'react'
import styled from "styled-components";
import DataCards from '../components/data-cards.components';
import RetailCurrentUserJourney from '../components/retail-current-user-journey.component';
import { ClicksB2CContext,
        B2CRegistrationsContext,
        B2CAppointmentsContext,
        B2COnlineBookingsContext,
        B2COrdersContext, 
        B2CRetailListContext,
        B2CDeliveryListContext,
        B2CTestDriveListContext,
        B2CCommissionContext,
      } from '../context/user.context';
import infoIcon from "../assets/images/info.png"
import { getAuth } from "firebase/auth";
import {  onUserB2CClicksListener,
          onUserB2CRegsitrationsListener,
          onUserB2CAppointmentsListener,
          onUserB2COnlineBookingsListener,
          onUserB2COrdersListener,
          onUserB2CRetailListListener,
          onUserB2CDeliveryListener,
          onUserB2CTestDriveListener,
} from "../utils/firebase.utils";

const Styles = styled.div`
  padding-top:1rem;
  &>.container{
    .data-card-cont{
      display:flex;
      flex-wrap:nowrap;
      justify-content start;
      margin-bottom:1rem;
      .data-card{
        margin-right:1rem;
        margin-bottom:1rem;
        width:25%;
        &:last-of-type{
          margin-right:0;
        }
      }
    }
  .userJourney{
    margin-top: 30px !important;
    margin-bottom: 50px !important;
  }
  .section-2{
    display:flex;
    align-items:start;
    margin-bottom:2rem;
    .table-cont{
      width:70%;
      margin-right:1rem;
    }
    .link-creator{
      width:30%;
    }
  }
  }
  .kpi-header{
    margin-bottom: 10px;
    font-weight: 500;
  }

  .inline-block{
    display: inline-block;
  }

  .info-icon{
    position: relative;
    display: inline-block;
    top: 6px;
    left: 10px;
  }

  .hover-text{
    position: absolute;
    top: 20px;
    left: 70%;
    background-color: white;
    border: 1px solid #E0E0E0;
    border-radius: 2px;
    padding: 10px 15px;
    font-weight: 400;
    font-size: 15px;
    width: max-content;
    line-height: 1.7;
    box-shadow: 0px 2px 2px 0px #00000040;
    min-width: 400px;
    z-index: 999;
  }

  @media screen and (max-width:1000px){
    &>.container{
      .data-card-cont{
        justify-content:start;
        margin-bottom:0;
        .data-card{
          margin-bottom:1rem;
          margin-right:1rem;
        }
      }
    }
  }
  @media screen and (max-width:850px){
    &>.container{
      .data-card-cont{
        margin-bottom:0;

        flex-wrap:wrap;
        .data-card{
          margin-bottom:1rem;
          &:nth-of-type(even){
            margin-left:10px;
            margin-right:0;
            width: calc( 50% - 10px );
          }
          &:nth-of-type(odd){
            margin-right:10px;
            margin-left:0;
            width: calc( 50% - 10px );
          }
        }
      }
    }
  }
  @media screen and (max-width:600px){
    &>.container{
      .data-card-cont{
        margin-bottom:0;
        justify-content:space-between;
        .data-card{
          margin-bottom:1rem;
          margin-left:0;
          margin-right:0;
        }
      }
      .section-2{
        flex-direction:column-reverse;
        .table-cont{
          width:100%;
          margin-right:0rem;
        }
        .link-creator{
          width:100%;
          margin-left:0rem;
          margin-bottom:1.5rem;
        }
      }
    }
  }
  @media screen and (max-width:410px){
    &>.container{
      .data-card-cont{
        margin-bottom:0;
        flex-direction:column;
        flex-wrap:nowrap;
        justify-content:start;
        align-items:stretch;
        .data-card{
          margin-bottom:1rem;
          margin-left:0!important;
          margin-right:0!important;
          width:100%!important;
          text-align:Center;
        }
      }
  }
  
`;


const Retail = () => {
  const {setUserB2CClicks} = useContext(ClicksB2CContext);
  const {userB2CRegistrations, setUserB2CRegistrations} = useContext(B2CRegistrationsContext);
  const {userB2CAppointments, setUserB2CAppointments} = useContext(B2CAppointmentsContext);
  const {userB2COnlineBookings, setUserB2COnlineBookings} = useContext(B2COnlineBookingsContext);
  const {userB2COrders, setUserB2COrders} = useContext(B2COrdersContext);
  const {userB2CRetails, setUserB2CRetailList} = useContext(B2CRetailListContext);
  const {userB2CDelivery, setUserB2CDelivery} = useContext(B2CDeliveryListContext);
  const {userB2CTestDrive,setUserB2CTestDrive } = useContext(B2CTestDriveListContext);


  const [isB2CHovering, setIsB2CHovering] = useState(false);
  const [isRetailLoading, setIsRetailLoading] = useState(true);

  const showHideB2CContent = () =>{
    const test = !isB2CHovering
    setIsB2CHovering(test);
  }

  const getRetailDetailsByUid = ()=>{
    const auth = getAuth();
    const user = auth.currentUser;

      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        
        // Retail-B2C
        onUserB2CClicksListener(user.uid, (response)=>{
            setUserB2CClicks(response)
        }) 

        onUserB2CRegsitrationsListener(user.uid, (res)=>{
            setUserB2CRegistrations(res)
        })

        onUserB2CAppointmentsListener(user.uid, (res)=>{
            setUserB2CAppointments(res)
        })

        onUserB2COnlineBookingsListener(user.uid, (res)=>{
            setUserB2COnlineBookings(res)
        })

        onUserB2COrdersListener(user.uid, (res)=>{
            setUserB2COrders(res)
        })

        onUserB2CRetailListListener(user.uid, (res)=>{
            setUserB2CRetailList(res)
            setIsRetailLoading(false)
        })

        onUserB2CDeliveryListener(user.uid, (res)=>{
            setUserB2CDelivery(res)
        })

        onUserB2CTestDriveListener(user.uid, (res)=>{
            setUserB2CTestDrive(res)
        })
      
      }
  }

  
  useEffect(() => {
    getRetailDetailsByUid()
  },[])
  

  return (
    <Styles>
      <div className='container'>
       
        <div id="retail-kpi">
          <p className='kpi-header inline-block'>Buy Car</p>
          <div className='info-icon'>
            <div onClick={showHideB2CContent}>
              <img src={infoIcon} alt="Info icon"/>
            </div>
            {isB2CHovering &&
            <div className='hover-text'>
              <p><b>Registration:</b> When the user signs up for the first time.</p>
              <p><b>Appointment:</b> When the user schedules a test drive appointment.</p>
              <p><b>Online Order:</b> When the user books a car online by paying the booking fee.</p>
              <p><b>Offline Order:</b> When the user books a car offline by paying the booking fee.</p>
              {/* <p><b>Turn Up:</b> When the user comes for the test drive.</p> */}
              <p><b>Test Drive:</b> When the user completes the test drive. </p>
              <p><b>Delivery:</b> When the user receives delivery of the car.</p>
            </div>
            }
          </div>
          <div className='data-card-cont'>
            <DataCards name={"Registration"} value={userB2CRegistrations}/>
            <DataCards name={"Appointment"} value={userB2CAppointments}/>
            <DataCards name={"Online Order"} value={userB2COnlineBookings}/>
            <DataCards name={"Offline Order"} value={userB2COrders}/>
            <DataCards name={"Test Drive"} value={userB2CTestDrive}/>
            <DataCards name={"Delivery"} value={userB2CDelivery}/>
          </div>
        </div>

        
        <div id="current-user-journey">
          <RetailCurrentUserJourney 
              data={userB2CRetails}
              isLoading={isRetailLoading}
          />
        </div>
      </div>
    </Styles>
  )
}

export default Retail