import React,{useContext, useState, useEffect} from 'react'
import { getAuth } from "firebase/auth";
import styled from "styled-components";
import bgImg from "../assets/images/dash-bg.png"
import buyCarTable from "../assets/images/buy-car-table.png"
import sellCarTable from "../assets/images/sell-car-table.png"  

const Styles = styled.div`
    padding-top: 1rem;
    min-height: 100vh;
    background-image: url(${bgImg}) !important;
    table {
      font-family: arial, sans-serif;
      border-collapse: collapse;
      width: 100%;
    }
    
    td, th {
      border: 1px solid #dddddd;
      text-align: left;
      padding: 8px;
    }
    
    tr:first-child {
      background-color: #dddddd;
    }

    .table-custom{
      padding: 30px 150px;
      p{
        font-weight: bold;
        font-size: 20px;
        margin-bottom: 10px;
      }
    }

    .container{
      text-align: -webkit-center;
      .buy-car-table{
        height: 320px;
        display: block;
      }
      .sell-car-table{
        height: 250px;
        display: block;
      }
    }

    @media screen and (max-width:600px){
      .container{
        text-align: -webkit-center;
        .buy-car-table{
          height: 140px;
          display: block;
        }
        .sell-car-table{
          height: 120px;
          display: block;
        }
      }
    }
`;

const Commission = () => {
  

  const getFAQsByUserId = ()=>{
    const auth = getAuth();
    const user = auth.currentUser;

      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        
      }
  }

  
  useEffect(() => {
    getFAQsByUserId()
      return () => {
        
      }
  },[])
  

  return (
    <Styles>
      <div className='container'>
            <img className="buy-car-table" src={buyCarTable} alt="carsome logo"/>
            <img className="sell-car-table" src={sellCarTable} alt="carsome logo"/>
      </div>


      {/* <div className='container table-custom'>
        <p>Retail - B2C</p>
        <table>
          <tr>
            <th>Country</th>
            <th>Incentive</th>
            <th>Remarks</th>
          </tr>
          <tr>
            <td>MY</td>
            <td>Upto RM1000*</td>
            <td>RM500 for selling price below RM100k. RM1000 for above RM100k </td>
          </tr>
          <tr>
            <td>ID</td>
            <td> - </td>
            <td> ** Coming Soon **</td>
          </tr>
          <tr>
            <td>TH</td>
            <td> - </td>
            <td> ** Coming Soon **</td>
          </tr>
        </table>
      </div>

      <div className='container table-custom'>
        <p>Wholesale - C2B</p>
        <table>
          <tr>
            <th>Country</th>
            <th>Incentive</th>
            <th>Remarks</th>
          </tr>
          <tr>
            <td>MY</td>
            <td>RM200</td>
            <td>For successful transaction via BuyOut only </td>
          </tr>
          <tr>
            <td>ID</td>
            <td> - </td>
            <td> ** Coming Soon **</td>
          </tr>
          <tr>
            <td>TH</td>
            <td> - </td>
            <td> ** Coming Soon ** </td>
          </tr>
        </table>
      </div> */}
    </Styles>
  )
}

export default Commission