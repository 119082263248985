import React from "react";
import Box from "@mui/material/Box";
import Lottie from "react-lottie";
import * as animationData from "./page-loader.json";
const PageLoader = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    // rendererSettings: {
    //   preserveAspectRatio: "xMidYMid slice",
    // },
  };
  return (
    <>
    <Box sx={{ display: "flex", margin:"30vh auto"}}>
      <Lottie
        options={defaultOptions}
        height={"25%"}
        width={"25%"}
      />
    </Box>
    </>
  );
};

export default PageLoader;
