
import React from 'react'
import Layout from './components/layout/layout.components'
import { Routes, Route } from "react-router-dom";
import Dashboard from './pages/dash.page';
import Retail from './pages/retail.page';
import Wholesale from './pages/wholesale.page';
import Home from './pages/home.page';
import NotFound from './pages/not-found.page';
import PrivateRoute from './components/private-route.components';
import AdminView from './pages/admin.page';
import FAQ from './pages/faq.page';
import Commission from './pages/commission.page';

const App = () => {
  
  return (
    <Routes>
        <Route element={<Layout />}>
          <Route
            path="/"
            element={
              <PrivateRoute not={true}>
                <Home/>
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/retail"
            element={
              <PrivateRoute>
                <Retail />
              </PrivateRoute>
            }
          />
          <Route
            path="/wholesale"
            element={
              <PrivateRoute>
                <Wholesale />
              </PrivateRoute>
            }
          />

          <Route
            path="/admin"
            element={
              <PrivateRoute>
                <AdminView />
              </PrivateRoute>
            }
          />

          <Route
            path="/faq"
            element={
              <PrivateRoute>
                <FAQ />
              </PrivateRoute>
            }
          />

          <Route
            path="/commission"
            element={
              <PrivateRoute>
                <Commission />
              </PrivateRoute>
            }
          />



          <Route path='*' exact={true} element={<NotFound/>} />
        </Route>
      </Routes>
  )
}

export default App
