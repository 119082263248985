import React,{useContext, useState, useEffect} from 'react'
import { getAuth } from "firebase/auth";
import styled from "styled-components";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import bgImg from "../assets/images/dash-bg.png"
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';




const Styles = styled.div`
    padding: 30px 150px;
    font-weight: 500px;
    min-height: 100vh;
    background-image: url(${bgImg}) !important;

    @media screen and (max-width:600px){
        padding: 0px 20px;
        .container{
            h2{
                font-size: 25px;
                font-weight: 400 !important;
            }
        }
    }

    Accordion > AccordionDetails > Typography{
        color: #727272;
    }
`;

const FAQ = () => {
  

  const getFAQsByUserId = ()=>{
    const auth = getAuth();
    const user = auth.currentUser;

      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        
      }
  }

  
  useEffect(() => {
    getFAQsByUserId()
      return () => {
        
      }
  },[])
  

  return (
    <Styles>
      <div className='container'>
        <h2 className='m-b-15 fw-5 m-t-20'>Frequently Asked Questions</h2>
        <Accordion>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            >
            <Typography>What is CARSOME Ambassador Program (CAP)?</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>
                    CARSOME Ambassador Program is an initiative for CARSOMErs to earn cash incentives for every referral that successfully buys a car or sells a car via your unique website link.
                </Typography>
            </AccordionDetails>
        </Accordion>
        
        <Accordion>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
            >
            <Typography>How to register as a CARSOME ambassador?</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
                Just Sign in with your CARSOME email address and you're set! 
            </Typography>
            </AccordionDetails>
        </Accordion>
        <Accordion>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3-content"
            id="panel3-header"
            >
            <Typography>Who is eligible to participate in the CARSOME Ambassador Program?</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
                CARSOME Ambassador Program is applicable to all internal employees. However, the below department mentioned will be excluded from this program. it's subject to change
                <List dense={true}>
            
                    <ListItem>
                        <ListItemText   primary="1. CARSOME Sdn Bhd"
                                    secondary='Country Customer Service, Country Operation Supply (Seller OPS & Inspection), Country Sourcing (UCD & NCD Supply, Retail Supply & Pricing, Corporate Supply, Central Pricing - Non Retail), Country Aftersales (Customer Service)' />
                    </ListItem>

                    <ListItem>
                        <ListItemText   primary="2. CARSOME Cerified Sdn Bhd"
                                    secondary='Country Retail (Retail Sales), Country Customer Service (Customer Service)' />
                    </ListItem>
                    
                    <ListItem>
                        <ListItemText   primary="3. ICAR Asia Sdn Bhd" />
                    </ListItem>

                    <ListItem>
                        <ListItemText   primary="4. Wapcar Auto Sdn Bhd"/>
                    </ListItem>
                </List>

            </Typography>
            </AccordionDetails>
        </Accordion>
        <Accordion>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4-content"
            id="panel4-header"
            >
            <Typography>Why should I join the CARSOME Ambassador Program?</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
            You can earn cash incentives easily for every referral that successfully buys a car or sells a car via your unique link. The best part? There's no limit to how much you can earn!
            </Typography>
            </AccordionDetails>
        </Accordion>
        {/* <Accordion>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel16-content"
            id="panel16-header"
            >
            <Typography>Who can Join ?</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
            Applicable to all carsome employees except Carsome Consultant(CC&CE), Branch Team Lead, Branch Assistant Manager and Branch Manager, NCD/UCD teams.
            </Typography>
            </AccordionDetails>
        </Accordion> */}
        <Accordion>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel6-content"
            id="panel6-header"
            >
            <Typography>What is considered a successful transaction for me to earn the cash reward?</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
                    For those that are buying a car from CARSOME, cash will be rewarded to Referrer upon successful car delivery to the customer. 
                    For those that are selling a car to CARSOME, cash will be rewarded to Referrer upon successful car sold to Carsome via bidding/buyout. 
                    Additional criteria: 1. The transaction must be completed within 60 days. Day 1 begins when the customer clicks your unique link.
            </Typography>
            </AccordionDetails>
        </Accordion>
        <Accordion>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel7-content"
            id="panel7-header"
            >
            <Typography>What are the cash reward amounts?</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
                If the referral buys a car from CARSOME:
                Malaysia
                - RM500 for every car priced below RM100k.
                - RM1,000 for every car priced should be RM100k & above.

                If the referral successful sold a car to CARSOME via bidding/buyout:
                Malaysia
                - RM200 for every car.
                
            </Typography>
            </AccordionDetails>
        </Accordion>
        <Accordion>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel8-content"
            id="panel8-header"
            >
            <Typography>Can CARSOME Ambassador Program be stacked with other reward programs?</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
                Yes. CARSOME Ambassador Program is stackable with other reward programs like public promotion and campaign showing in Carsome website.
            </Typography>
            </AccordionDetails>
        </Accordion>
        <Accordion>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel10-content"
            id="panel10-header"
            >
            <Typography>When and how will I receive my cash reward?</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
                All successful cash rewards will be finalized by the 10th of each month. The payout will be credited together with your monthly salary.
            </Typography>
            </AccordionDetails>
        </Accordion>
        <Accordion>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel11-content"
            id="panel11-header"
            >
            <Typography>Is the cash reward taxable?</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
                Yes, the cash reward is taxable. 
            </Typography>
            </AccordionDetails>
        </Accordion>

        <Accordion>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel12-content"
            id="panel12-header"
            >
            <Typography>Is there a limit to the amount of cash reward I can earn?</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
                No, you can earn as much as you want. So, take advantage of the promo seasons!
            </Typography>
            </AccordionDetails>
        </Accordion>

        <Accordion>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel13-content"
            id="panel13-header"
            >
            <Typography>Who can I reach out to if I have questions about the CARSOME Ambassador Program?</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
                {/* You can join our Workplace Community (insert name) to ask questions and get the latest news about CARSOME Ambassador Program.  */}
                You can write us <b>support_cap@carsome.com</b>  
                <br></br>
                <br></br>
                <u>Contact us via workchat</u>
                <br></br>
                 Carien Lam
                 <br></br>
                 Mahindar Leburu
                <br></br>
                 Venkata Harish Kumar Veerepalli
            </Typography>
            </AccordionDetails>
        </Accordion>

        <Accordion>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel14-content"
            id="panel14-header"
            >
            <Typography>What happens if there is an unfortunate incentive dispute in any way, shape or form? </Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
                In unfortunate situations like these, all disputes will be resolved by CARSOME. CARSOME decision is final.
            </Typography>
            </AccordionDetails>
        </Accordion>

        <Accordion>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel15-content"
            id="panel15-header"
            >
            <Typography>Will the cash incentives rates change over time?</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
                Yes, cash incentive rates are subject to change from time to time.
            </Typography>
            </AccordionDetails>
        </Accordion>

        <Accordion>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel15-content"
            id="panel15-header"
            >
            <Typography>Is this CARSOME Ambassador Program support by Carsome App Malaysia?</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
                No, currently the CARSOME Ambassador Program only supports web browser. Therefore, please ensure that your referees use a web browser when purchasing or selling a car with us after clicking on your unique link.
            </Typography>
            </AccordionDetails>
        </Accordion>

        <Accordion>
            <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel15-content"
            id="panel15-header"
            >
            <Typography>How to know what Carsome ongoing promotion in monthly?</Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography>
                Please join <b>Carsome Ambassador Program (CAP) Community Group</b> in workchat group get to know the latest promotion.
            </Typography>
            </AccordionDetails>
        </Accordion>

       
      </div>

    </Styles>
  )
}

export default FAQ