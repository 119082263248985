import React from 'react'
import { useContext, useEffect } from 'react';
import styled from "styled-components"
import Button from '../components/button.components'
import { BTN_FILL, DARK_BLUE, WHITE, YELLOW } from '../constants/style.contstants'
import heroImg from "../assets/images/cap-hero-img.png"
import logo from "../assets/images/Logo-dark.png"
import { signInWithGooglePopup } from '../utils/firebase.utils';
import { REQUEST_FAILED, REQUEST_PENDING } from '../constants/transaction.constants';
import { UserContext } from '../context/user.context';
import HeroImg from "../assets/images/hero-image.jpg"
import HeroMobImg from "../assets/images/hero-image-mobile.png"
import carBg from "../assets/images/car-pattern.png"
import step1 from "../assets/images/step-1.png"
import step2 from "../assets/images/step-2.png"
import step3 from "../assets/images/step-3.png"
import buyCarTable from "../assets/images/buy-car-table.png"
import sellCarTable from "../assets/images/sell-car-table.png"
import coinLeft from "../assets/images/coins-left.png"
import coinRight from "../assets/images/coin-right.png"
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";
const Styles = styled.div`
  background-color: #fff;

  .container-1{
    background-image: url(${HeroImg});
    height: 600px;
    text-align: center;
    position:relative;
    overflow:hidden;
    color: #fff;
    font-size: 30px;
    text-align: center;
    background-size: cover;
    background-color: #fdcf34;
    .hero-btn{
      position: absolute;
      bottom: 20%;
      left: 10%;
      button{
        background-color: #00205B;
        color: #fff !important;
        padding: 15px 70px;
      }
    }
    .container-1-text{
      text-align: center;
        h1{
          font-size: 45px;
        }
        p{
          padding: 15px 200px;
          font-size: 25px;
          line-height: 1.3;
        }
    }
    img{
      height: 600px;
      object-fit:contain;
    }
  }

  .container-2{
    background-color: white;
    color: black;
    min-height: 900px;
    .video-container{
      text-align: center;
      iframe{
        border-radius: 10px;
        margin-top: 40px;
        // margin-top: -150px;
      }
      .cap-intro-video{
        width: 1000px;
        height: 550px;
      }
    }
    .img-container{
      margin-top: 20px;
      text-align: center;
      img{
        height: 480px;
      }
    }
  }

  .container-3{
    background-image: url(${carBg}) !important;    
    text-align: center;
    min-height: 100vh;
    .video-container{
      iframe{
        border-radius: 10px;
        margin-top: 40px;
      }
      .cap-intro-video{
        width: 700px;
        height: 400px;
      }
    }
    h1{
      margin-bottom: 20px;
      font-weight: 300;
    }
    .commission{
      text-align: -webkit-center;
      .buy-car-table{
        max-height: 320px;
        text-align: center;
        display: block;
        margin-bottom: 30px;
      }
      .sell-car-table{
        max-height: 250px;
        text-align: center;
        display: block;
      }
    }
    .coin-section{
      margin-bottom: 15px;
      img{
        display: inline-block;
        margin: 0px 40px;
        height: 50px;
      }
      h1{
        display: inline-block;
      }
      .custom-text{
        text-align:center;
        margin-top: 20px;
      }
    }
    .count-me-btn > button{
      background-color:#00205B;
      color: #fff;
      padding: 10px 60px;
      margin-bottom: 10px;
    }
  }

  .footer-container{
    background-color: #fdcf34;
    text-align:Center;
    height: 200px;
    .content{
      padding-top: 7vh !important;
      img{
        height: 50px;
      }
    }
  }

  @media only screen and (min-width: 1550px) {
    .container-1{
      background-image: url(${HeroImg});
      background-repeat: no-repeat;
      background-size: cover;
      padding: 10px;
      align-items:center;
      display:flex;
      height: 850px;
      flex-direction:column;
      align-items:center;
      text-align:Center;
      .container-1-text{
        text-align: center;
        h1{
          font-size: 35px;
        }
        p{
          padding: 15px;
          font-size: 20px;
          line-height: 1.3;
        }
      }
      .btn{
        font-size:18px;
      }
      .hero-btn{
        position: absolute;
        bottom: 25%;
        left: 10%;
        button{
          background-color: #00205B;
          color: #fff !important;
          padding: 10px 50px;
          font-size: 35px;
        }
      }
    }

  }

  // Mobile Responsive
  @media screen and (max-width:600px){
    .container-1{
      background-image: url(${HeroMobImg});
      background-repeat: no-repeat;
      background-size: cover;
      padding: 10px;
      align-items:center;
      display:flex;
      height: 650px;
      flex-direction:column;
      align-items:center;
      text-align:Center;
      .container-1-text{
        text-align: center;
        h1{
          font-size: 35px;
        }
        p{
          padding: 15px;
          font-size: 20px;
          line-height: 1.3;
        }
      }
      .btn{
        font-size:18px;
      }
      .hero-btn{
        position: absolute;
        bottom: 45%;
        left: 10%;
        button{
          background-color: #00205B;
          color: #fff !important;
          padding: 10px 50px;
        }
      }
    }
    
    .container-2{
      background-color: white;
      color: black;
      min-height: 500px;
      .img-container{
        text-align: center;
        margin-top: 40px;
        h1{
          margin-bottom: 20px;
          font-size: 35px;
        }
        img{
          display: block;
          height: 450px;
          margin: auto;
        }
      }
      .video-container{
        .cap-intro-video{
          width: 80%;
          height: 180px;
        }
      }
    }

    .container-3{
      h1{
        font-size: 30px;
      }
      .video-container{
        .cap-intro-video{
          width: 80%;
          height: 180px;
        }
      }
      min-height: 80vh;
       .commission {
        .buy-car-table{
          max-height: 140px;
        }
        .sell-car-table{
          max-height: 115px;
        }
       }
       .coin-section {
        .custom-text{
          text-align: center;
          font-size: 16px;
          margin-top: 15px;
        }

        img{
          display: inline-block;
          margin: 0px 15px;
          height: 20px;
        }
       }
    }

    .footer-container{
      background-color: #fdcf34;
      text-align:Center;
      height: 200px;
      .content{
        padding-top: 10vh !important;
        img{
          height: 35px !important;
        }
      }
    }
  }

`

const Home = () => {
  const {setCurrentUser} = useContext(UserContext);
  const auth = getAuth();
  const user = auth.currentUser;
  let navigate = useNavigate();
  useEffect(() => {
    if(user){
      navigate("/dashboard");
    }
    
  }, [user])
  

  const logGoogleUser = async () => {
    window.analytics.track("Get Started Button Clicked", {
        "path": window.location.host + '' + window.location.pathname,
        "url": window.location.href,
    });
    try{
      setCurrentUser( REQUEST_PENDING() );
      await signInWithGooglePopup();
    }
    catch(e){
      setCurrentUser( REQUEST_FAILED(e.message) );
    }
}
  return (
    <Styles>
      <div className='container-1'>
        {/* <img className="desktopVersion" src={HeroImg} alt="Hero Image"/>
        <img className="mobileVersion" src={HeroMobImg} alt="Hero Image"/> */}
          <Button className="hero-btn " onClick={logGoogleUser}>Let's Make Money</Button>
      </div>



      <div className='container-2 m-b-30'>
        <div className='video-container'>
          <iframe className="cap-intro-video" allow="fullscreen;"  src="https://drive.google.com/file/d/1Y1mOd4lkqMDNAzPunNOaj6Ts8L8UNLBT/preview" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
       <div className='img-container'>
          <h1 className='text-center fw-3 m-t-50 m-b-30'><span className='fw-5'>3 small steps</span> to earn big!</h1>
          <img src={step1} alt="carsome logo"/>
          <img src={step2} alt="carsome logo"/>
          <img src={step3} alt="carsome logo"/>
       </div>
      </div>

      <div className='container-3 p-t-30'>
          <h1 className='text-center fw-3 m-t-20'><span className='fw-5'>How much</span> can you earn?</h1>
          <div className='commission m-b-40'>
              <img className="buy-car-table" src={buyCarTable} alt="carsome logo"/>
              <img className="sell-car-table" src={sellCarTable} alt="carsome logo"/>
          </div>

          <div className='video-container m-b-50'>
            <iframe className="cap-intro-video" allow="fullscreen;"  src="https://drive.google.com/file/d/1fPwz41rtv8JeJA1fMwnfoPjFPRBZVWhF/preview" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>

          <div className='coin-section'>
              <img src={coinLeft} alt="carsome logo"/>
              <h1 className='custom-text fw-3'>Let's make some <span className='fw-5'>big bucks!</span></h1>
              <img src={coinRight} alt="carsome logo"/>
          </div>
          
          <Button className="p-b-30 count-me-btn " onClick={logGoogleUser}>Count Me In</Button>
          
          <p className='p-b-30 fw-3'><MailOutlineIcon className='va-middle m-r-5'/>Need help? Reach us at <span className='fw-5'>support_cap@carsome.com</span></p>

      </div>


      <div className='footer-container'>
          <div className='content'>
            <img className="b-image" src={logo} alt="carsome logo"/>  
            <p className='clr-black m-t-10 '>Beep Beep!!</p>
          </div>
      </div>


    </Styles>
  )
}

export default Home
