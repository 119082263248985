import React,{useState,useContext} from 'react'
import styled from "styled-components"
import Input from './input.components'
import Button from './button.components'
import { UserContext } from '../context/user.context'
import { createShortenLink } from '../utils/firebase.utils'
import { PENDING, REQUEST_FAILED, REQUEST_PENDING, REQUEST_SUCCESS } from '../constants/transaction.constants'
import { YELLOW } from '../constants/style.contstants'
import { _isNotEmpty } from '../utils/validations.utils'
import { generateQrcode } from '../utils/url-shortner.utils'
import QRCode from 'qrcode'
import QrCode2Icon from '@mui/icons-material/QrCode2';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import infoIcon from "../assets/images/info.png"
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { getAuth } from 'firebase/auth'


const Style = styled.div`
    border:1px solid #E0E0E0;
    min-width:10rem;
    padding: 1rem 1.5rem;
    border-radius:0.3rem;
    min-height: 460px;
    box-shadow: 0px 2px 2px 0px #00000040;
    background:white;
    &>form{
        // display:flex;
        // justify-content:center;
        // align-items:Center;
        // flex-direction:column;
        .commision-info{
            width:100%;
            margin-bottom:1rem;
            display:flex;
            justify-content:start;
            align-items:Center;
            .icon{
                width:1em;
                padding-top:0.28em;
                margin-right:0.2rem;
            }
            .text{
                font-size:0.8em;
                width:100%;
            }
        }
    }
    .c-input{
        margin-bottom:1rem;
    }
    .btn-cont{
        margin:0 auto;
        margin-bottom:1rem;
        margin-top:1rem;
    }
    .copy-text{
        display:flex;
        justify-content:space-between;
        align-items:Center;
        background: #F3F3F3;
        border: 1px solid #E0E0E0;
        box-sizing: border-box;
        border-radius: 5px;
        padding:0.4rem 0.4rem;
        cursor:pointer;
        width:100%;
        .text-to-copy{
            font-size:1em;
            white-space:nowrap;
            overflow:hidden;
            color:#4b4b4b;
        }
        .indicator{
            white-space:nowrap;
            color:white;
            font-size:0.8em;
            padding:0.3em 0.5em;
            border-radius:0.1em;
            margin-left:5px;
            min-height: 20px !important;
        }
    }
    .custom-input-fields{
        border: 1px solid #ccc;
        background-color: #f5f5f5;
        box-shadow: none !important;
        border-radius: 5px;
    }
    .pull-left{
        float: left !important;
    }
    .pull-right{
        float: right !important;
    }

    .m-b-5{
        margin-bottom: 5px !important;
    }
    .m-t-5{
        margin-top: 5px !important;
    }
    .m-b-20{
        margin-bottom: 20px !important;
    }
    .customize-btn{
        display: flex;
        flex-direction: column;
    }
    .custom-img{
        height: 20px;
    }
    .generate-btn{
        color: #000000;
    }
    .display-block{
        display: inline-block;
    }
    .w100{
        width: 100%;
    }
    .custom-dropdown{
        background-color: #f5f5f5;
        padding: 5px;
        border-radius: 4px;
        border: 1px solid #ccc;
    }
    .active{
        display: block;
    }
    .hide{
        display: none;
    }
    .info-icon{
        position: relative;
        display: inline-block;
        top: 6px;
        left: 10px;
    }
    .h20{
        height: 20px;
    }
    .hover-text{
        position: absolute;
        top: 20px;
        left: 70%;
        background-color: white;
        border: 1px solid #E0E0E0;
        border-radius: 2px;
        padding: 10px 15px;
        font-weight: 400;
        font-size: 15px;
        width: max-content;
        line-height: 1.7;
        box-shadow: 0px 2px 2px 0px #00000040;
        max-width: 500px;
        z-index: 9999;
      }
      .copy-btn{
        color: #fdcf33 !important;
      }

    .context{
        font-size: 14px;
        padding-bottom: 5px;
        padding-left: 5px;
        color: #727272;
    }

    .required-icon{
        font-weight: bold;
        font-size: 12px;
        vertical-align: text-top;
        color: red;
    }
`
const defaultLinkForm = {
    url:"",
    link: "",
    reference: "",
    region: "",
}

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});




const LinkCreator = () => {
    const auth = getAuth();
    const user = auth.currentUser;
    const [open, setOpen] = React.useState(false);
    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };


    const [ linkForm,setLinkForm ] = useState( defaultLinkForm )
    const [ linkRequest, setLinkRequest ] = useState( REQUEST_SUCCESS({isCopied:false}) );
    const { currentUser } = useContext(UserContext)
    const [qrSrc, setqrSrc] = useState("");
    const handleChange = (e) => {
        setLinkForm(
               { ...linkForm,
                [e.target.name]:e.target.value
               })
    }
    const downloadQRCode = () => {
        var a = document.createElement("a"); //Create <a>
        a.href = qrSrc; //Image Base64 Goes here
        a.download = "Image.png"; //File name Here
        a.click(); 
        
      };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const {reference} = linkForm;
        
        if(linkForm.url != 'custom-url'){
            if(linkForm.region == "" || linkForm.region == undefined){
                alert("Please select Region");
                return;
            }else if(linkForm.url == "" || linkForm.url == undefined){
                alert("Please select business");
                return;
            }
        }
        
        if(linkForm.url == "" || linkForm.url == undefined){
            alert("Please select business")
            return;
        }

        if(linkForm.reference == "" || linkForm.reference == undefined){
            alert("Please enter reference")
            return;
        }

        let final_url = "";
        if(linkForm.url == 'custom-url'){
            final_url = linkForm.link;
        }else{
            if(linkForm.region == 'my'){
                if(linkForm.url == 'buy-car'){
                    final_url = "https://www.carsome.my/buy-car"
                }else if(linkForm.url == 'sell-car'){
                    final_url = "https://www.carsome.my/sell-car"
                }
            }else if(linkForm.region == 'id'){
                if(linkForm.url == 'buy-car'){
                    final_url = "https://www.carsome.id/beli-mobil-bekas"
                }else if(linkForm.url == 'sell-car'){
                    final_url = "https://www.carsome.id/jual-mobil-bekas"
                }
            }else if(linkForm.region == 'th'){
                if(linkForm.url == 'buy-car'){
                    final_url = "https://www.carsome.co.th/buy-car"
                }else if(linkForm.url == 'sell-car'){
                    final_url = "https://www.carsome.co.th/sell-car"
                }
            }
        }    
        var trimmedLink = final_url.includes("?")? final_url.substring(0,final_url.indexOf("?")):final_url;
        if(!trimmedLink){
            alert("Please enter valid link.");
            return;
        }
        if(!trimmedLink.match(/^(http:\/\/||https:\/\/)?/igm)[0]){
            trimmedLink = "https://"+trimmedLink;
        }
        try{
            setLinkRequest( REQUEST_PENDING({isCopied:false}) )
            const generatedLink = await createShortenLink({link: trimmedLink, ref1:reference, user_name: user.displayName, user_email:user.email, user_id:user.uid});
            // const respose = await  generateQrcode({url: generatedLink})
            const respose = await QRCode.toDataURL(generatedLink)
            if(respose){
            //  setqrSrc(respose?.data?.src)
             setqrSrc(respose)
            }
      
            // setLinkForm( linkForm );

            window.analytics.track("Generate Link Button Clicked", {
                "url": final_url,
                "reference" : reference,
                "generated_url": generatedLink,
                "path": window.location.host + '' + window.location.pathname,
            });

            setLinkRequest( REQUEST_SUCCESS({
                link:generatedLink,
                isCopied:false,
            }) );
        }
        catch(error){
            console.log(error)
            var message = "Link generation failed";
            if(error.response){
                const mapErrorCodeToMessage = {
                    'INVALID_ARGUMENT':"Link is not valid"
                }
                message = mapErrorCodeToMessage[error.response.data.error.status]||"Link generation failed"
            }
            setLinkForm( defaultLinkForm );
            alert(message+", Please try again");
            setLinkRequest( REQUEST_FAILED(message,{isCopied:false}) );
        }

    }
    const copyText = () =>{
        if(!linkRequest.data.link) return;
        navigator.clipboard.writeText(linkRequest.data.link);
        setLinkRequest((prev)=>{
            return {
                ...prev,
                data:{
                    ...prev.data,
                    isCopied:true,
                }

            }
        })
    }

    const copySegmentEvent = (shortenerLink)=>{
        window.analytics.track("Link Copied", {
            "copied_link": shortenerLink,
            "path": window.location.host + '' + window.location.pathname,
            "url": window.location.href,
        });
    }
    
    const [isRegionHovering, setRegionHovering] = useState(false);
    const [isBusinessHovering, setBusinessHovering] = useState(false);
    const [isReferenceHovering, setReferenceHovering] = useState(false);

    const showHideRegion = () =>{
      const test = !isRegionHovering
      setRegionHovering(test);
    }
    
    const showHideBusiness = () =>{
        const test = !isBusinessHovering
        setBusinessHovering(test);
    }

    const showHideReference = () =>{
        const test = !isReferenceHovering
        setReferenceHovering(test);
    }
    

  if( !_isNotEmpty( currentUser ) ) return;

  return (
      <Style className='link-creator' isCopied={linkRequest.data.isCopied}>
          <h4 className='m-b-10 fw-5'>Generate Referral Link</h4>
          <form onSubmit={handleSubmit}>
            {linkForm.url !=="custom-url" &&  <> 
            <label className='m-b-5 display-block' id="region-label">
                Region<span className='required-icon'>*</span>
            </label>
            <div className='info-icon'>
                <div onClick={showHideRegion}>
                    <img height="20px" src={infoIcon} alt="Info icon"/>
                </div>
                {isRegionHovering &&
                    <div className='hover-text'>
                        This field is for you your reference to input a name( whether a friends name or group name or social media name) and checks the data in the referral tracker
                    </div>
                }
            </div>  
            <select onChange={handleChange} className='custom-dropdown m-b-20 w100' value={linkForm.region} name="region" id="region">
                <option value="" selected disabled>Select your country</option>
                <option value="my">Malaysia</option>
                <option value="id">Indonesia</option>
                <option value="th">Thailand</option>
            </select> </>}

            <label className='m-b-10 display-block'>
                Business<span className='required-icon'>*</span>
            </label>
            <div className='info-icon'>
                <div onClick={showHideBusiness}>
                    <img height="20px" src={infoIcon} alt="Info icon"/>
                </div>
                {isBusinessHovering &&
                    <div className='hover-text'>
                        <p><b>Sell Car:</b> If you are referring your friend to sell his car(C2B business).</p>
                        <p><b>Buy Car:</b> If you are referring your friends to BUY ANY car(B2C business).</p>
                        <p><b>Custom URL:</b> If you are referring your friends to BUY any specific car or specific brand models, copy that link from the CARSOME website and paste it here.. </p>
                    </div>
                }
            </div>  
            <select onChange={handleChange} className='custom-dropdown m-b-20 w100' value={linkForm.url} name="url" id="url">
                <option value="" selected disabled>Select your business</option>
                <option value="buy-car">Buy Car</option>
                <option value="sell-car">Sell Car</option>
                <option value="custom-url">Custom</option>
            </select>

          {linkForm.url ==="custom-url" &&  <Input id="custom-url-path" className='custom-input-fields m-b-20' autoComplete="off" placeholder="Copy-paste car model link from https://www.carsome.my/" onChange={handleChange} value={linkForm.link} name="link"/>} 
            
        
            <label className='m-b-5'>
                Reference<span className='required-icon'>*</span>
            </label>
            <div className='info-icon'>
                <div onClick={showHideReference}>
                    <img height="20px" src={infoIcon} alt="Info icon"/>
                </div>
                {isReferenceHovering &&
                    <div className='hover-text'>
                        This field is for you your reference to input a name( whether a friends name or group name or social media name) and checks the data in the referral tracker
                        Ex: If I am generating the link and sharing it with my Family WhatsApp group, then the reference name is WhatsApp.
                    </div>
                }
            </div>  
            <Input className='custom-input-fields' autoComplete="off" placeholder="Who is this link for? E.g. Eric or family." onChange={handleChange} value={linkForm.reference} name="reference"/>

            <div className='customize-btn'>
                <Button className="generate-btn" type="submit" isLoading={linkRequest.status===PENDING} color={YELLOW}> 
                    <AutoFixHighIcon className='va-middle m-r-10' fontSize='medium'/>Generate Link
                </Button>
            </div>
          </form>

        {
            linkRequest.data.link&&
            ( 
                <>
                <p className='context'>Pick up your unique link here</p>
                <div className='copy-text' onClick={copyText} >
                    <div className='text-to-copy'>
                        {linkRequest.data.link}
                    </div>
                    <span className='indicator' onClick={()=>{copySegmentEvent(linkRequest.data.link); handleClick()}}>
                        <FileCopyIcon fontSize='medium' className="copy-btn"/>
                    </span>
                </div>
                </>
            )
        }

        <Stack spacing={2} sx={{ width: '100%'}}>
            <Snackbar open={open} autoHideDuration={1300} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                Link Copied Successfully!!
                </Alert>
            </Snackbar>
        </Stack>


        {/* {
            linkRequest.data.link && 
            <div className='customize-btn'>
            <Button className="generate-btn" onClick={downloadQRCode}  color={YELLOW}> 
                <QrCode2Icon className="va-middle"/> Download QR Code 
            </Button>
        </div>
        } */}


      </Style>
  )
}

export default LinkCreator
