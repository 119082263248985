import React from "react";
import Box from "@mui/material/Box";
import Lottie from "react-lottie";
import * as animationData from "./kpi-loader.json";
const KPILoader = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    // rendererSettings: {
    //   preserveAspectRatio: "xMidYMid slice",
    // },
  };
  return (
    <>
    {/* <Box sx={{ display: "flex", margin:"50vh auto"}}> */}
      <Lottie
        options={defaultOptions}
        height={"75px"}
        width={"100%"}
      />
    {/* </Box> */}
    </>
  );
};

export default KPILoader;
