import React, { useContext, useEffect,useState } from "react";
import styled from "styled-components";
import Table from "../components/table.component";
import LinkCreator from "../components/link-creator.component";
import { UserClicksContext,
        ClicksB2CContext,
} from "../context/user.context";
import { getAuth } from "firebase/auth";
import { onUserB2CClicksListener} from "../utils/firebase.utils";
import bgImg from "../assets/images/dash-bg.png"

const Styles = styled.div`
  padding-top:1rem;
  background-image: url(${bgImg}) !important;
  &>.container{
    .data-card-cont{
      display:flex;
      flex-wrap:nowrap;
      justify-content start;
      margin-bottom:1rem;
      .data-card{
        margin-right:1rem;
        margin-bottom:1rem;
        width:25%;
        &:last-of-type{
          margin-right:0;
        }
      }
    }
  .section{
    display:flex;
    align-items:start;
    margin-bottom:2rem;
    justify-content: center;
    margin-top: 1rem;
    .table-cont{
      width:70%;
      margin-right:1rem;
    }
    .link-creator{
      width:50%;
    }
    .content{
      width: 50%;
      line-height: 1.4;
      padding: 10px;
    }
  }
  }
  .kpi-header{
    margin-bottom: 10px;
    font-weight: 500;
  }

  .inline-block{
    display: inline-block;
  }

  .info-icon{
    position: relative;
    display: inline-block;
    top: 6px;
    left: 10px;
  }

  .hover-text{
    position: absolute;
    top: 20px;
    left: 70%;
    background-color: white;
    border: 1px solid #E0E0E0;
    border-radius: 2px;
    padding: 10px 15px;
    font-weight: 400;
    font-size: 15px;
    width: max-content;
    line-height: 1.7;
    box-shadow: 0px 2px 2px 0px #00000040;
  }

  @media screen and (max-width:1000px){
    &>.container{
      .data-card-cont{
        justify-content:start;
        margin-bottom:0;
        .data-card{
          margin-bottom:1rem;
          margin-right:1rem;
        }
      }
    }
  }
  @media screen and (max-width:850px){
    &>.container{
      .data-card-cont{
        margin-bottom:0;

        flex-wrap:wrap;
        .data-card{
          margin-bottom:1rem;
          &:nth-of-type(even){
            margin-left:10px;
            margin-right:0;
            width: calc( 50% - 10px );
          }
          &:nth-of-type(odd){
            margin-right:10px;
            margin-left:0;
            width: calc( 50% - 10px );
          }
        }
      }
    }
  }
  @media screen and (max-width:600px){
    &>.container{
      .data-card-cont{
        margin-bottom:0;
        justify-content:space-between;
        .data-card{
          margin-bottom:1rem;
          margin-left:0;
          margin-right:0;
        }
      }
      .section{
        flex-direction:column-reverse;
        .table-cont{
          width:100%;
          margin-right:0rem;
        }
        .link-creator{
          width:100%;
          margin-left:0rem;
          margin-bottom:1.5rem;
        }
      }
      .content{
        width: 100% !important;
      }
    }
  }
  @media screen and (max-width:410px){
    &>.container{
      .data-card-cont{
        margin-bottom:0;
        flex-direction:column;
        flex-wrap:nowrap;
        justify-content:start;
        align-items:stretch;
        .data-card{
          margin-bottom:1rem;
          margin-left:0!important;
          margin-right:0!important;
          width:100%!important;
          text-align:Center;
        }
      }
  }
`;

const Dashboard = () => {
  const {userClicks} = useContext(UserClicksContext)
  const { setUserB2CClicks } = useContext(ClicksB2CContext);
  const [isUserClickLoading, setIsUserClickLoading]=useState(true)
  const getDashboardDetailsByUid = () => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      onUserB2CClicksListener(user.uid, (response) => {
        setUserB2CClicks(response);
        setIsUserClickLoading(false)
      });
    }
  };

  useEffect(() => {
    getDashboardDetailsByUid();
  }, []);

  return (
    <Styles>
      <div className="container">
        <div className="section">
          <LinkCreator />
        </div>
      </div>

      <div className="container">
          <Table data={userClicks} isLoading={isUserClickLoading} />
      </div>
    </Styles>
  );
};

export default Dashboard;
