export const REFERRALS_RESPONSE = ({
    createdAt="",
    campaign="NULL",
    category="",
    country="",
    reference="",
    tId="",
    id=""
}) => {
    return {
        date:createdAt,
        campaign,
        category,
        country,
        reference,
        tId,
        id
    }
}

export const USER_STATUS_RESPONSE = ({
    displayName="",
    email="",
    createdAt="",
    value=0,
    paymentApproved=0,
    id=""
}) =>{
    return {
        displayName,
        email,
        createdAt,
        value,
        paymentApproved,
        id
    }
}