import { CircularProgress, Skeleton, Typography } from '@mui/material';
import React from 'react'
import styled from "styled-components";
import { isEmpty } from '../utils/validations.utils';
import KPILoader from './KPILoader/KPILoader';

const Style = styled.div`
border:1px solid #E0E0E0;
display:inline-block;
min-width:10rem;
padding:0.8rem 1rem;
border-radius:0.3rem;
box-shadow: 0px 2px 2px 0px #00000040;
background:white;
height: 90px;
.data-name{
  font-size:1em;
  font-weight:400;
  margin-bottom:0.2rem;
}
.data-value{
  font-size:1.5rem;
  font-weight:500;
  margin-top: 0.6rem;
}

`

const DataCards = ({name, value}) => {
  return (
    <Style className='data-card'>
        <div className='data-name'>
            {name}
        </div>
      
        <div className='data-value'>
          {isEmpty(value) ? <KPILoader />: value} 
        </div>
    </Style>
  )
}

export default DataCards