import React, { useEffect } from 'react'
import { useState,useContext } from 'react';
import styled from 'styled-components'
import logo from "../../assets/images/Logo-dark.png"
import Button from '../button.components';
import { BLACK, BTN_TRANS, YELLOW, BTN_TRANS_MOB, DARK_BLUE, WHITE } from '../../constants/style.contstants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars,faXmark } from '@fortawesome/free-solid-svg-icons'
import { UserContext } from '../../context/user.context';
import { signOutUser,signInWithGooglePopup } from '../../utils/firebase.utils';
import { PENDING, REQUEST_FAILED, REQUEST_PENDING, REQUEST_SUCCESS } from '../../constants/transaction.constants';
import { _isNotEmpty } from '../../utils/validations.utils';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import UserDefaultAvatar from "../../assets/images/user.png"
import LogoutIcon from '@mui/icons-material/Logout';


const Style = styled.header`
  .btn{
    color: #00205B !important;
    font-weight: 500 !important;
  }
  nav{
    position:fixed;
    top:0;
    left:0;
    width:100%;
    // background: #00205B;
    background: #fdcf34;
    z-index:1000;
  }
  .nav-dummy{
    height:70px;
  }
  .sign-in-btn{
    font-size: 18px;
    padding: 10px 20px;
    background: transparent;
    border-radius: 50px;
    color: #fff;
    border: 2px solid #fff;
    cursor: pointer;
  }
  .sign-btns{
    button{
      color: #fff !important;
    }
  }
  nav>.container{
    display:flex;
    justify-content:space-between;
    align-items:Center;
    height:70px;
    .menu-btn{
      font-size:1.3em;
      cursor:pointer;
      display:none;
      margin-left:15px;
    }
    #logo{
      height:25px;
    }
    &>div{
      display:flex;
      align-items:center;
    }
    & .left-nav{
      justify-content:start;
    }
    & .right-nav{
      justify-content:end;
      & .btn-cont{
        margin-left:1rem;
      }
    }
    .nav-menu{
      margin-left: 100px;
      display:flex;
      justify-content:start;
      align-items:center;
      list-style-type:none;
      &>li{
        margin:0 10px;
        &>.btn-cont{
          display:flex;
          justify-content:center;
          align-items:center;
          font-size: 18px;
        }
        .btn{
          padding: 0 0.5em;
          font-weight: 400;
        }
      }
    }
    @media screen and (max-width:900px){
      .nav-menu{
        display:none;
      }
      .right-nav .sign-btns{
        display:none;
      }
      .menu-btn{
        display:inline-block;
        color: #fff;
      }
    }
  }
  .menu-overlay{
    position:fixed;
    top:0;
    right:0;
    left:0;
    bottom:0;
    width:100%;
    z-index:1002;
    background:#000000bb;
  }

  .active-menu div a{
    // color:#fff !important;
  }

  .mobile-menu{
    position:fixed;
    top:0;
    right:0;
    z-index:1003;
    background:white;
    height:100vh;
    width:220px;
    border-right:1px solid #f1f1f1;
    padding:40px 30px;
    transition:0.3s ease;
    line-height: 1.5;
    transform:translateX(${({isOpen})=>isOpen?"0px":"100%"});
    color: black !important;
    .cross-cont{
      display:inline-block;
      cursor:pointer;
      font-size:1.3em;
      position:absolute;
      top:15px;
      right:15px;
    }
    h4{
      margin-bottom:1rem
    }
    hr{
      margin:20px 0;
    }
    .menu,.menu-2{
      list-style-type:none;
      &>li {
        margin-bottom:0.5rem;
        .btn{
          font-weight:500;
        }
      }
    }
    .menu{
      .btn{
        padding:0;
      }
    }
    
  }
  .user-info{
    display: inline-block;
    margin-left: -5px !important;
    button{
      color: #00205B !important;
      border: none !important;
      background: none !important;
      font-weight: 500 !important;
    }
    .btn{
      padding: 0px !important;
      cursor: auto;
      border-radius: 0px !important;
      text-decoration: none !important;
    }
  }

  .user-avatar{
    height: 40px;
    margin-right: 20px;
    display: inline-block;
    border-radius: 25px;
    vertical-align: middle;
  }


  
`;

const Header = () => {
  const [isOpen,setOpen] = useState(false);
  const {currentUser,setCurrentUser} = useContext(UserContext);

  const adminEmails = ['mahindar.leburu@carsome.com', 'harish.veerepalli@carsome.com', 'vishnuteja.gunanjipalli@carsome.com', 'carmen.too@carsome.com', 'ibrahim.alnameh@carsome.com', 'jeeyin@carsome.com'];
  const isAdmin = adminEmails.includes(currentUser.data.email);

  const [anchorEl, setAnchorEl] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    console.log(event)
  };
  const handleClose = () => {
    setAnchorEl(false);
  };

  const signOutHandler = async () => {
    try{
      setCurrentUser( REQUEST_PENDING() );
      await signOutUser();
      setCurrentUser( REQUEST_SUCCESS() );
    }
    catch(e){
      setCurrentUser( REQUEST_FAILED(e.message) )
      console.log(e.message)
    }
    window.analytics.track("Sign Out", {
        "path": window.location.host + '' + window.location.pathname,
        "url": window.location.href,
    });
  }
  const logGoogleUser = async () => {
    window.analytics.track("Signin Button Clicked", {
        "path": window.location.host + '' + window.location.pathname,
        "url": window.location.href,
    });
    try{
      setCurrentUser( REQUEST_PENDING() );
      await signInWithGooglePopup();
      setOpen(false)
    }
    catch(e){
      setCurrentUser( REQUEST_FAILED(e.message) );
    }
}


// let parent_ele = document.getElementById('menu-items');
// if (parent_ele){
//   let nested_ele = parent_ele.getElementsByTagName('li');
//   if(window.location.pathname === '/dashboard'){
//     nested_ele[0].className = 'active-menu';
//     nested_ele[1].classList.remove('active-menu');
//     nested_ele[2].classList.remove('active-menu');
//     if(nested_ele[3])
//       nested_ele[3].classList.remove('active-menu');
//   }else if(window.location.pathname === '/retail'){
//     nested_ele[1].className = 'active-menu';
//     nested_ele[0].classList.remove('active-menu');
//     nested_ele[2].classList.remove('active-menu');
//     if(nested_ele[3])  
//       nested_ele[3].classList.remove('active-menu');
//   }else if(window.location.pathname === '/wholesale'){
//     nested_ele[2].className = 'active-menu';
//     nested_ele[0].classList.remove('active-menu');
//     nested_ele[1].classList.remove('active-menu');
//     if(nested_ele[3])
//       nested_ele[3].classList.remove('active-menu');
//   }else if(window.location.pathname === '/admin'){
//     nested_ele[3].className = 'active-menu';
//     nested_ele[0].classList.remove('active-menu');
//     nested_ele[1].classList.remove('active-menu');
//     nested_ele[2].classList.remove('active-menu');
//   }
// }

const menuTabClickedEvent = (eventName) =>{
  const event_name = eventName +' Tab Clicked'
  const url_event = "/"+eventName.toLowerCase()
  if(url_event === window.location.pathname){
    // if(url_event !== '/dashboard'){
      window.analytics.track(event_name, {
        "path": window.location.host + '' + window.location.pathname,
        "url": window.location.href,
      });
    // }
  }

}


  return (
    <Style isOpen={isOpen}>
        <nav>
          <div className='container'>
            <div className='left-nav'>
              <div className="img contain" id="logo">
                <img src={logo} alt="carsome logo"/>
              </div>

              {
                (_isNotEmpty( currentUser.data ))&&(
                  <ul className="nav-menu" id="menu-items">
                    <li id="dashboard" onClick={()=>menuTabClickedEvent('Dashboard')}>
                      <Button to="/dashboard" looks={BTN_TRANS} color={WHITE}>
                          Generate Link
                      </Button>
                    </li>
                    <li id="retail" onClick={()=>menuTabClickedEvent('Retail')}>
                      <Button to="/retail" looks={BTN_TRANS} color={WHITE}>
                        Buy Car
                      </Button>
                    </li>
                    <li id="wholesale" onClick={()=>menuTabClickedEvent('Wholesale')}>
                      <Button to="/wholesale" looks={BTN_TRANS} color={WHITE}>
                        Sell Car
                      </Button>
                    </li>

                    
                    {isAdmin &&
                      <li id="admin" onClick={()=>menuTabClickedEvent('Admin')}>
                        <Button to="/admin" looks={BTN_TRANS} color={BLACK}>
                          Admin
                        </Button>
                      </li>
                    }

                      
                  </ul>
                )
              }

            </div>
            <div className="right-nav">
            {(_isNotEmpty( currentUser.data)) &&( 
              <ul className="nav-menu" id="menu-items">
                <li id="comission" onClick={()=>menuTabClickedEvent('Commission')}>
                  <Button to="/commission" looks={BTN_TRANS} color={BLACK}>
                      Incentive Rates
                  </Button>
                </li>

                <li id="faq" className='m-l--15' onClick={()=>menuTabClickedEvent('FAQ')}>
                  <Button to="/faq" looks={BTN_TRANS} color={BLACK}>
                      FAQs
                  </Button>
                </li>
              </ul>
              )
            }

              <div className='sign-btns'>
                {(_isNotEmpty( currentUser.data ))?
                  (
                    <div> 
                      <img className="user-avatar" src={currentUser.data.photoURL ? currentUser.data.photoURL : UserDefaultAvatar} alt=""/> 
                      <Button
                        className="user-info "
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}>
                        {currentUser.data.displayName}
                      </Button>
                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                          'aria-labelledby': 'basic-button',
                        }}
                        > 
                        <MenuItem onClick={signOutHandler}>
                          <LogoutIcon sx={{ fontSize: 18, mariginRight: 5 }}  color="action"/>Logout  
                        </MenuItem>
                      </Menu>
                      <ArrowDropDownIcon fontSize="large" className="cp va-middle" onClick={handleClick}/>
                      {/* <button className="sign-in-btn" onClick={signOutHandler} isLoading={currentUser.status === PENDING} >Sign out</button>   */}
                      
                    </div>
                  ):(
                    <>
                      <Button color={DARK_BLUE} id="login-btn" onClick={()=> logGoogleUser()} isLoading={currentUser.status === PENDING}>SIGN IN</Button>
                    </>
                  )
                }

              </div>
              <div className='menu-btn' onClick={()=>setOpen((temp)=>!temp)}>
                <FontAwesomeIcon icon={faBars} />
              </div>
            </div>
          </div>
        </nav>
        {
          isOpen&&<div className='menu-overlay'></div>
        }

        <div className='mobile-menu'>

          <div className='cross-cont' onClick={()=>setOpen(false)}>
            <FontAwesomeIcon icon={faXmark} />
          </div>
            {
                (_isNotEmpty( currentUser.data ))&&(
                  <>
                    <ul className="menu">
                      <li>
                        <Button to="/dashboard" looks={BTN_TRANS_MOB} color={YELLOW} onClick={()=>setOpen(false)}>
                          Generate Link
                        </Button>
                      </li>
                      <li>
                        <Button to="/retail" looks={BTN_TRANS_MOB} color={YELLOW} onClick={()=>setOpen(false)}>
                          Buy Car
                        </Button>
                      </li>
                      <li>
                        <Button to="/wholesale" looks={BTN_TRANS_MOB} color={YELLOW} onClick={()=>setOpen(false)}>
                          Sell Car
                        </Button>
                      </li>

                      {isAdmin &&
                        <li id="admin" onClick={()=>menuTabClickedEvent('Admin')}>
                          <Button to="/admin" looks={BTN_TRANS_MOB} color={YELLOW} onClick={()=>setOpen(false)}>
                            Admin
                          </Button>
                        </li>
                      }

                    <li id="comission" onClick={()=>menuTabClickedEvent('Dashboard')}>
                      <Button to="/commission" looks={BTN_TRANS_MOB} color={YELLOW} onClick={()=>setOpen(false)}>
                          Incentive Rates
                      </Button>
                    </li>

                    <li id="faq" onClick={()=>menuTabClickedEvent('Dashboard')}>
                      <Button to="/faq" looks={BTN_TRANS_MOB} color={YELLOW} onClick={()=>setOpen(false)}>
                          FAQs
                      </Button>
                    </li>

                    </ul>
                    <hr/>
                  </>
                )
            }


          <ul className="menu-2">
                {
                  (_isNotEmpty( currentUser.data ))?
                  (
                    <li>
                      <Button color={YELLOW} onClick={signOutHandler} isLoading={currentUser.status === PENDING} >Sign out</Button>  
                    </li>
                  ):(
                    <li>
                      <Button color={YELLOW} id="login-btn" onClick={()=> logGoogleUser()} isLoading={currentUser.status === PENDING}>Login with Google</Button>
                    </li>
                  )
                }
                  
          </ul>
        </div>
        <div className='nav-dummy'></div>
    </Style>
  )
}

export default Header