import React, {useMemo} from 'react'
import MaterialReactTable from "material-react-table";
import Tooltip from "@mui/material/Tooltip"
import { Box } from "@mui/material";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { ExportToCsv } from 'export-to-csv'; 

const WholesaleAllUserJourney = ({schema='',data=[], isLoading}) => {
    const columns = useMemo(
        () => [
          {
            accessorKey: "t_id", //access nested data with dot notation
            header: "Lead ID",
          },
          {
            accessorKey: "reference",
            header: "Reference",
          },
          {
            accessorKey: "country",
            header: "Country",
          },
          {
            accessorFn: (row) => `${row?.lead?.props?.src}`, //accessorFn used to join multiple data into a single cell
            id: "lead", //id is still required when using accessorFn instead of accessorKey
            header: "Lead",
            size: 150,
            Cell: ({ cell, row }) => (
              <Box
                sx={{
                  marginLeft: "10px"
                }}
              >
                {row?.original?.lead?.props?.src ? (
                  <Tooltip title={row.original.lead.props.title}>
                    <img
                      alt="avatar"
                      height={15}
                      src={row.original.lead.props.src}
                      loading="lazy"
                      style={{ borderRadius: "50%" }}
                    />
                  </Tooltip>
                ) : (
                  ""
                )}
              </Box>
            ),
          },
          {
            accessorKey: "lead_timestamp",
            header: "Lead Created At"
          },
          {
            accessorFn: (row) => `${row?.online_appointment?.props?.src}`, //accessorFn used to join multiple data into a single cell
            id: "online_appointment", //id is still required when using accessorFn instead of accessorKey
            header: "Online Appointment",
            size: 250,
            Cell: ({ cell, row }) => (
              <Box
                sx={{
                  marginLeft: "15px"
                }}
              >
                {row?.original?.online_appointment?.props?.src ? (
                  <Tooltip title={row.original.online_appointment.props.title}>
                    <img
                      alt="avatar"
                      height={15}
                      src={row.original.online_appointment.props.src}
                      loading="lazy"
                      style={{ borderRadius: "50%" }}
                    />
                  </Tooltip>
                ) : (
                  ""
                )}
              </Box>
            ),
          },
          {
            accessorKey: "online_appointment_timestamp",
            header: "Online Appointment Created At",
            size: 250

          },
          {
            accessorFn: (row) => `${row?.inspection?.props?.src}`, //accessorFn used to join multiple data into a single cell
            id: "inspection", //id is still required when using accessorFn instead of accessorKey
            header: "Inspection",
            size: 250,
            Cell: ({ cell, row }) => (
              <Box
                sx={{
                  marginLeft: "15px"
                }}
              >
                {row?.original?.inspection?.props?.src ? (
                  <Tooltip title={row.original.inspection.props.title}>
                    <img
                      alt="avatar"
                      height={15}
                      src={row.original.inspection.props.src}
                      loading="lazy"
                      style={{ borderRadius: "50%" }}
                    />
                  </Tooltip>
                ) : (
                  ""
                )}
              </Box>
            ),
          },
          {
            accessorKey: "inspection_timestamp",
            header: "Inspection Created At",
            size: 250
          },
          {
            accessorFn: (row) => `${row?.purchase?.props?.src}`, //accessorFn used to join multiple data into a single cell
            id: "purchase", //id is still required when using accessorFn instead of accessorKey
            header: "Purchase",
            size: 250,
            Cell: ({ cell, row }) => (
              <Box
                sx={{
                  marginLeft: "15px"
                }}
              >
                {row?.original?.purchase?.props?.src ? (
                  <Tooltip title={row.original.purchase.props.title}>
                    <img
                      alt="avatar"
                      height={15}
                      src={row.original.purchase.props.src}
                      loading="lazy"
                      style={{ borderRadius: "50%" }}
                    />
                  </Tooltip>
                ) : (
                  ""
                )}
              </Box>
            ),
          },
          {
            accessorKey: "purchase_timestamp",
            header: "Purchase Created At",
            size: 250
          }
        ],
        []
      );
      const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        showTitle: true,
        title: 'Wholesale User Journey',
        filename:'wholesale-user-journey',
        useKeysAsHeaders: false,
        headers: columns.map((c) => c.header),
      };
      const csvExporter = new ExportToCsv(csvOptions);
      const handleExportRows = (rows) => {
        csvExporter.generateCsv(rows.map((row) =>  {
        
          const data = {
            t_id:row?.original?.t_id ? row?.original?.t_id :'',
            reference:row?.original?.reference ? row?.original?.reference :"",
            country:row?.original?.country ? row?.original?.country:"",
            lead:row?.original?.lead?.props?.src ? 'yes':'',
            leadcapname:row?.original?.lead_cap_name?.props?.children ? `${row?.original?.lead_cap_name?.props?.children[0]}`+`,`+`${row?.original?.lead_cap_name?.props?.children[2]}`:"",
            lead_timestamp:row?.original?.lead_timestamp ? row?.original?.lead_timestamp:"",
            online_appointment:row?.original?.online_appointment?.props?.src ? 'yes':'',
            onappcapname:row?.original?.online_appointment_cap_name?.props?.children ? `${row?.original?.online_appointment_cap_name?.props?.children[0]}`+`,`+`${row?.original?.online_appointment_cap_name?.props?.children[2]}`:"",
            online_appointment_timestamp:row?.original?.online_appointment_timestamp ? row?.original?.online_appointment_timestamp :"",
            inspection:row?.original?.inspection?.props?.src ? 'yes':'',
            inspection_timestamp:row?.original?.inspection_timestamp ? row?.original?.inspection_timestamp:"" ,
            purchase:row?.original?.purchase?.props?.src ? 'yes':'',
            purchase_timestamp:row?.original?.purchase_timestamp ? row?.original?.purchase_timestamp :"",
          }
          return data;
        }));
      };
  return (
    <MaterialReactTable
        columns={columns}
        data={data}
        enableHiding={true}
        enableFullScreenToggle={false}
        enableDensityToggle={false}
        enablePagination={false}
        enableColumnActions={false}
        enableStickyHeader
        initialState={
          { 
            density: 'compact',
            columnVisibility: {
              purchase_timestamp:false,
              inspection_timestamp: false,
              online_appointment_timestamp: false,
              lead_timestamp: false
            } 
          }
        }
        muiLinearProgressProps={({ isTopToolbar }) => ({
          color: 'secondary',
          variant: 'indeterminate', //if you want to show exact progress value
          // value: progress, //value between 0 and 100
          sx: {
            display: isTopToolbar ? 'block' : 'none', //hide bottom progress bar
          },
        })}
        state={{isLoading:isLoading}}
        muiTableContainerProps={{ sx: { minHeight: "300px" } }}
        renderTopToolbarCustomActions={({ table }) => {
          return (
            <div style={{ display: "flex", gap: "0.5rem" }}>
              <p className='bold'>Referral Sell Car Journey</p>
              {table.getRowModel().rows.length !== 0  ?  <Tooltip title="Download Report">
                  <FileDownloadIcon  className="download-btn" onClick={() =>
              handleExportRows(table.getPrePaginationRowModel().rows)
            } fontSize='medium'/>
              </Tooltip>:""}
            </div>
          );
        }}
      />
  )
}

export default WholesaleAllUserJourney