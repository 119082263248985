import { createContext,useEffect,useState } from "react";
import { REFERRALS_RESPONSE, USER_STATUS_RESPONSE } from "../constants/response.constans";
import { format } from 'date-fns'
import { createUserDocumentFromAuth, 
            onUserStateChangeListener, 
            onAuthStateChangedListener, 
            onUserReferralsStateChangedListener, 
            onUserConversionListener, 
            onUserClicksListener,
        } from "../utils/firebase.utils";
import { useNavigate } from "react-router-dom";
import { REQUEST_PENDING, REQUEST_SUCCESS } from "../constants/transaction.constants";
import { _isNotEmpty } from "../utils/validations.utils.js"

export const UserContext = createContext({
    currentUser: REQUEST_PENDING(),
    setCurrentUser:() => null
})

export const ReferralsContext = createContext({
    userReferrals: [],
    setUserReferrals:() => null
})

export const ConversionContext = createContext({
    userConversions: [],
    setUserConversions:() => null
})

export const UserStatusContext = createContext({
    userStatus: null,
    setUserStatus:() => null
})

export const UserClicksContext = createContext({
    userClicks: null,
    setUserClicks: ()=> null
})

// WholeSale - C2B
export const ClicksC2BContext = createContext({
    userC2BClicks: [],
    setUserC2BClicks:() => null
})  

export const C2BLeadsContext = createContext({
    userC2BLeads: null,
    setUserC2BLeads:() => null
})

export const C2BOnlineAppointmentsContext = createContext({
    userC2BOnlineAppointments: null,
    setUserC2BOnlineAppointments:() => null
})

export const C2BInspectionsContext = createContext({
    userC2BInspections: null,
    setUserC2BInspections: () => null
})

export const C2BPurchasesContext = createContext({
    userC2BPurchases: null,
    setUserC2BPurchases:() => null
})

export const C2BCommissionContext = createContext({
    userC2BCommissions: null,
    setUserC2BCommissions:() => null
})

export const C2BCurrentUserJourneyContext = createContext({
    userC2BCurrentUserJourney: null,
    setUserC2BCurrentUserJourney:() => null
})

export const C2BAllUserJourneyContext = createContext({
    userC2BAllUserJourney: null,
    setUserC2BAllUserJourney: ()=> null
})


// Retail - B2C
export const ClicksB2CContext = createContext({
    userB2CClicks: [],
    setUserB2CClicks:() => null
})

export const B2CRegistrationsContext = createContext({
    userB2CRegistrations: null,
    setUserB2CRegistrations: () => null
})

export const B2CAppointmentsContext = createContext({
    userB2CAppointments: null,
    setUserB2CAppointments: () => null
})

export const B2COnlineBookingsContext = createContext({
    userB2COnlineBookings: null,
    setUserB2COnlineBookings: () => null
})

export const B2COrdersContext = createContext({
    userB2COrders: null,
    setUserB2COrders: () => null
})

export const B2CCommissionContext = createContext({
    userB2CCommissions: null,
    setUserB2CCommissions:() => null
})

export const B2CRetailListContext = createContext({
    userB2CRetails: null,
    setUserB2CRetailList: () => null
})

export const B2CRetailAllUserJourneyContext = createContext({
    userB2CAllUserJourney: null,
    setUserB2CAllUserJourneyList: () => null
})

export const B2CTestDriveListContext = createContext({
    userB2CTestDrive: null,
    setUserB2CTestDrive: () => null
})

export const B2CDeliveryListContext = createContext({
    userB2CDelivery: null,
    setUserB2CDelivery: () => null
})


// Admin View
// B2C
export const B2CAllRegistrationsContext = createContext({
    allUserB2CRegistrations: null,
    setAllUserB2CRegistrations: () => null
})

export const B2CAllAppointmentsContext = createContext({
    allUserB2CAppointments: null,
    setAllUserB2CAppointments: () => null
})

export const B2CAllOnlineBookingsContext = createContext({
    allUserB2COnlineBookings: null,
    setAllUserB2COnlineBookings: () => null
})

export const B2CAllOrdersContext = createContext({
    allUserB2COrders: null,
    setAllUserB2COrders: () => null
})

export const B2CAllTestDriveContext = createContext({
    allUserB2CTestDrive: null,
    setAllUserB2CTestDrive: ()=> null
})

export const B2CAllDeliveryContext = createContext({
    allUserB2CDelivery: null,
    setAllUserB2CDelivery: ()=> null
})


// C2B
export const C2BAllLeadsContext = createContext({
    allUserC2BLeads: null,
    setAllUserC2BLeads:() => null
})

export const C2BAllOnlineAppointmentsContext = createContext({
    allUserC2BOnlineAppointments: null,
    setAllUserC2BOnlineAppointments:() => null
})

export const C2BAllInspectionsContext = createContext({
    allUserC2BInspections: null,
    setAllUserC2BInspections: () => null
})

export const C2BAllPurchasesContext = createContext({
    allUserC2BPurchases: null,
    setAllUserC2BPurchases:() => null
})




export const UserProvider = ({children}) => {

    let navigate = useNavigate();

    const [currentUser, setCurrentUser] = useState( REQUEST_PENDING() )
    const [userReferrals, setUserReferrals] = useState([]);
    const [userStatus, setUserStatus] = useState([]);
    const [userConversions, setUserConversions] = useState([]);

    const [userClicks, setUserClicks] = useState([]);
    const userClicksValue = {userClicks, setUserClicks};

    const value = {currentUser, setCurrentUser};
    const refValue = {userReferrals, setUserReferrals};
    const USValue = {userStatus, setUserStatus};
    const conversionsValue = {userConversions, setUserConversions};

    // B2C
    const [userB2CClicks, setUserB2CClicks] = useState([]);
    const [userB2CRegistrations, setUserB2CRegistrations] = useState([]);
    const [userB2CAppointments, setUserB2CAppointments] = useState([]);
    const [userB2COnlineBookings, setUserB2COnlineBookings] = useState([]);
    const [userB2COrders, setUserB2COrders] = useState([]);
    const [userB2CCommissions, setUserB2CCommissions] = useState([]);
    const [userB2CRetails, setUserB2CRetailList] = useState([]);
    const [userB2CAllUserJourney, setUserB2CAllUserJourneyList] = useState([]);
    const [userB2CDelivery, setUserB2CDelivery] = useState([]);
    const [userB2CTestDrive, setUserB2CTestDrive] = useState([]);


    const clicksB2CValue = {userB2CClicks, setUserB2CClicks};
    const B2CRegistrationsValue = {userB2CRegistrations, setUserB2CRegistrations};
    const B2CAppointmentsValue = {userB2CAppointments, setUserB2CAppointments};
    const B2COnlineBookingsValue = {userB2COnlineBookings, setUserB2COnlineBookings};
    const B2COrdersValue = {userB2COrders, setUserB2COrders};
    const commissionB2CValue = {userB2CCommissions, setUserB2CCommissions};
    const B2CRetailList = {userB2CRetails, setUserB2CRetailList};
    const B2CAllUserJourney = {userB2CAllUserJourney, setUserB2CAllUserJourneyList};
    const B2CDeliveryValue = {userB2CDelivery, setUserB2CDelivery};
    const B2CTestDriveValue = {userB2CTestDrive, setUserB2CTestDrive}

    // C2B
    const [userC2BClicks, setUserC2BClicks] = useState([]);
    const [userC2BLeads, setUserC2BLeads] = useState([]);
    const [userC2BOnlineAppointments, setUserC2BOnlineAppointments] = useState([]);
    const [userC2BPurchases, setUserC2BPurchases] = useState([]);
    const [userC2BInspections, setUserC2BInspections] = useState([]);
    const [userC2BCommissions, setUserC2BCommissions] = useState([]);
    const [userC2BCurrentUserJourney, setUserC2BCurrentUserJourney] = useState([]);
    const [userC2BAllUserJourney, setUserC2BAllUserJourney] = useState([]);


    const clicksC2BValue = {userC2BClicks, setUserC2BClicks};
    const C2BLeadsValue = {userC2BLeads, setUserC2BLeads};
    const C2BOnlineAppointmentsValue = {userC2BOnlineAppointments, setUserC2BOnlineAppointments};
    const C2BPurchasesValue = {userC2BPurchases, setUserC2BPurchases};
    const C2BInspectionsValue = {userC2BInspections, setUserC2BInspections};
    const commissionC2BValue = {userC2BCommissions, setUserC2BCommissions};
    const C2BCurrentUserJourney = {userC2BCurrentUserJourney, setUserC2BCurrentUserJourney};
    const C2BAllUserJourney = {userC2BAllUserJourney, setUserC2BAllUserJourney};


    // Admin View
    // B2C
    const [allUserB2CRegistrations, setAllUserB2CRegistrations] = useState(null);
    const [allUserB2CAppointments, setAllUserB2CAppointments] = useState(null);
    const [allUserB2COnlineBookings, setAllUserB2COnlineBookings] = useState(null);
    const [allUserB2COrders, setAllUserB2COrders] = useState(null);
    const [allUserB2CTestDrive, setAllUserB2CTestDrive] = useState(null);
    const [allUserB2CDelivery, setAllUserB2CDelivery] = useState(null);

    const B2CAllRegistrationsValue = {allUserB2CRegistrations, setAllUserB2CRegistrations};
    const B2CAllAppointmentsValue = {allUserB2CAppointments, setAllUserB2CAppointments};
    const B2CAllOnlineBookingsValue = {allUserB2COnlineBookings, setAllUserB2COnlineBookings};
    const B2CAllOrdersValue = {allUserB2COrders, setAllUserB2COrders};
    const B2CAllTestDriveValue = {allUserB2CTestDrive, setAllUserB2CTestDrive};
    const B2CAllDeliveryValue = {allUserB2CDelivery, setAllUserB2CDelivery};


    // C2B
    const [allUserC2BLeads, setAllUserC2BLeads] = useState(null);
    const [allUserC2BOnlineAppointments, setAllUserC2BOnlineAppointments] = useState(null);
    const [allUserC2BPurchases, setAllUserC2BPurchases] = useState(null);
    const [allUserC2BInspections, setAllUserC2BInspections] = useState(null);
    const C2BAllLeadsValue = {allUserC2BLeads, setAllUserC2BLeads};
    const C2BAllOnlineAppointmentsValue = {allUserC2BOnlineAppointments, setAllUserC2BOnlineAppointments};
    const C2BAllPurchasesValue = {allUserC2BPurchases, setAllUserC2BPurchases};
    const C2BAllInspectionsValue = {allUserC2BInspections, setAllUserC2BInspections};


    useEffect(()=>{
      const unsubscribe =  onAuthStateChangedListener( async (user)=>{
            setCurrentUser( REQUEST_SUCCESS( user||{} ) )
            if(user){
                const lastLoginAt = sessionStorage.getItem("lastLoginAt");
                if(lastLoginAt){
                    const currentDate = format(new Date(), 'yyyy-MM-dd')
                    if(currentDate !== lastLoginAt){
                        sessionStorage.removeItem("lastLoginAt");
                        sessionStorage.setItem("lastLoginAt", format(new Date(), 'yyyy-MM-dd'));
                        await createUserDocumentFromAuth(user)
                    }
                }else{
                    sessionStorage.setItem("lastLoginAt", format(new Date(), 'yyyy-MM-dd'));
                    await createUserDocumentFromAuth(user)
                }
            }
        })
    return unsubscribe;
    },[])
    useEffect( ()=>{
        if(_isNotEmpty( currentUser.data )){
            onUserReferralsStateChangedListener(currentUser.data.uid,(newDoc)=>{
                setUserReferrals((prev)=>{
                    return [
                        ...prev,
                        REFERRALS_RESPONSE(newDoc)
                    ]
                })
            })

            onUserConversionListener(currentUser.data.uid, (res)=>{
                setUserConversions(res)
            })

            onUserClicksListener(currentUser.data.uid, (res)=>{
                setUserClicks(res)
            })
            
            onUserStateChangeListener(currentUser.data.uid,(data)=>{
                setUserStatus( USER_STATUS_RESPONSE(data) )
            })
            
           
        }else{
            setUserReferrals([])
            setUserStatus({})
            setUserC2BClicks([])
            setUserB2CClicks([])
            setUserConversions([])
        }
    },[currentUser.data])
    
    return (
            <UserContext.Provider value={value}>
            <ReferralsContext.Provider value={refValue}>
            <UserStatusContext.Provider value={USValue}>
            <ConversionContext.Provider value={conversionsValue}>
            <UserClicksContext.Provider value={userClicksValue}>
            <ClicksC2BContext.Provider value={clicksC2BValue}>
            <C2BLeadsContext.Provider value={C2BLeadsValue}>
            <C2BOnlineAppointmentsContext.Provider value={C2BOnlineAppointmentsValue}>
            <C2BPurchasesContext.Provider value={C2BPurchasesValue}>            
            <C2BInspectionsContext.Provider value={C2BInspectionsValue}>                    
            <C2BCommissionContext.Provider value={commissionC2BValue}> 
            <C2BCurrentUserJourneyContext.Provider value={C2BCurrentUserJourney}>
            <C2BAllUserJourneyContext.Provider value={C2BAllUserJourney}>
            <ClicksB2CContext.Provider value={clicksB2CValue}>
            <B2CRegistrationsContext.Provider value={B2CRegistrationsValue}>
            <B2CAppointmentsContext.Provider value={B2CAppointmentsValue}>
            <B2COnlineBookingsContext.Provider value={B2COnlineBookingsValue}>
            <B2COrdersContext.Provider value={B2COrdersValue}>
            <B2CCommissionContext.Provider value={commissionB2CValue}> 
            <B2CRetailListContext.Provider value={B2CRetailList}>
            <B2CRetailAllUserJourneyContext.Provider value={B2CAllUserJourney}>
            <B2CAllRegistrationsContext.Provider value={B2CAllRegistrationsValue}>
            <B2CAllAppointmentsContext.Provider value={B2CAllAppointmentsValue}>
            <B2CAllOnlineBookingsContext.Provider value={B2CAllOnlineBookingsValue}>
            <B2CAllOrdersContext.Provider value={B2CAllOrdersValue}>
            <B2CAllTestDriveContext.Provider value={B2CAllTestDriveValue}>
            <B2CAllDeliveryContext.Provider value={B2CAllDeliveryValue}>
            <B2CDeliveryListContext.Provider value={B2CDeliveryValue}>    
            <B2CTestDriveListContext.Provider value={B2CTestDriveValue}>
            <C2BAllLeadsContext.Provider value={C2BAllLeadsValue}>
            <C2BAllOnlineAppointmentsContext.Provider value={C2BAllOnlineAppointmentsValue}>
            <C2BAllInspectionsContext.Provider value={C2BAllInspectionsValue }>
            <C2BAllPurchasesContext.Provider value={C2BAllPurchasesValue}>
                {children}
            </C2BAllPurchasesContext.Provider>
            </C2BAllInspectionsContext.Provider>
            </C2BAllOnlineAppointmentsContext.Provider>
            </C2BAllLeadsContext.Provider>
            </B2CTestDriveListContext.Provider>
            </B2CDeliveryListContext.Provider>
            </B2CAllDeliveryContext.Provider>
            </B2CAllTestDriveContext.Provider>
            </B2CAllOrdersContext.Provider>
            </B2CAllOnlineBookingsContext.Provider>
            </B2CAllAppointmentsContext.Provider>
            </B2CAllRegistrationsContext.Provider> 
            </B2CRetailAllUserJourneyContext.Provider>
            </B2CRetailListContext.Provider>
            </B2CCommissionContext.Provider> 
            </B2COrdersContext.Provider>
            </B2COnlineBookingsContext.Provider>
            </B2CAppointmentsContext.Provider>
            </B2CRegistrationsContext.Provider>
            </ClicksB2CContext.Provider>
            </C2BAllUserJourneyContext.Provider>
            </C2BCurrentUserJourneyContext.Provider>
            </C2BCommissionContext.Provider> 
            </C2BInspectionsContext.Provider>
            </C2BPurchasesContext.Provider> 
            </C2BOnlineAppointmentsContext.Provider>
            </C2BLeadsContext.Provider>
            </ClicksC2BContext.Provider>
            </UserClicksContext.Provider>
            </ConversionContext.Provider>
            </UserStatusContext.Provider>
            </ReferralsContext.Provider>
            </UserContext.Provider>

    )
}