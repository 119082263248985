export const WHITE = "#ffffff";
export const BLACK = "#000000";
export const L_BLACK = "#343434";
export const BLUE = "#1E3A5F";
export const L_BLUE = "#fdcf33";
export const GREY = "#E0E0E0";
export const YELLOW = "#fdcf33";
export const BTN_FILL = "fill";
export const BTN_TRANS = "transparent";
export const BTN_BORDER = "border";
export const BTN_SIGN_OUT_BORDER = "border";
export const BTN_TRANS_MOB = "#fdcf33";
export const DARK_BLUE = "#00205B";