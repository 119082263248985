import React,{ useMemo} from 'react'
import MaterialReactTable from "material-react-table";
import '../index.css';
import '../helper.css';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Tooltip from '@mui/material/Tooltip';


const download_data = () => {
    var separator = ",";
    var table_id = "Conversions_list";
    var rows = document.querySelectorAll("table tr");
    var csv = [];
    for (var i = 0; i < rows.length; i++) {
      var row = [],
        cols = rows[i].querySelectorAll("th, td");
      for (var j = 0; j < cols.length; j++) {
        var data = cols[j].innerText
          .replace(/(\r\n|\n|\r)/gm, "")
          .replace(/(\s\s)/gm, " ");
        data = data.replace(/"/g, '""');
        row.push('"' + data + '"');
      }
      csv.push(row.join(separator));
    }
    var csv_string = csv.join("\n");
    var filename = table_id + "_" + new Date().toLocaleDateString() + ".csv";
    var link = document.createElement("a");
    link.style.display = "none";
    link.setAttribute("target", "_blank");
    link.setAttribute(
      "href",
      "data:text/csv;charset=utf-8," + encodeURIComponent(csv_string)
    );
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    window.analytics.track("Referral Tracker Report Downloaded", {
      path: window.location.host + "" + window.location.pathname,
      url: window.location.href,
    });
};

const Table = ({ schema = "", data = [], isLoading }) => {
  const columns = useMemo(() => [
      {
        accessorKey: "clickCategory",
        header: "Category",
        size: 100,
      },
      {
        accessorKey: "reference",
        header: "Reference",
        size: 200,
      },
      {
        accessorKey: "referral_link",
        header: "Landing Page Link",
        size: 250,
      },
      {
        accessorKey: "createdAt",
        header: "Date",
        size: 200,
      },
    ],
    []
  );
 
  return (
      <div className="w100 m-r-15">
        <MaterialReactTable
          columns={columns}
          data={data}
          enableHiding={false}
          enableFullScreenToggle={false}
          enableDensityToggle={false}
          enablePagination={false}
          enableColumnActions={false}
          enableStickyHeader
          initialState={{ density: "compact" }}
          muiLinearProgressProps={({ isTopToolbar }) => ({
            color: 'secondary',
            variant: 'indeterminate', //if you want to show exact progress value
            // value: progress, //value between 0 and 100
            sx: {
              display: isTopToolbar ? 'block' : 'none', //hide bottom progress bar
            },
          })}
          muiTableContainerProps={{ sx: { maxHeight: "350px", minHeight: "350px"}}}
          state={{isLoading:isLoading}}
          renderTopToolbarCustomActions={({ table }) => {
            return (
              <div style={{ display: "flex", gap: "0.5rem" }}>
                <p className="fw-5">Who Clicked Your Link</p>
                <Tooltip title="Download Report">
                    <FileDownloadIcon className="download-btn" onClick={download_data} fontSize='medium'/>
                </Tooltip>
              </div>
            );
          }}
        />
      </div>
  );
};

export default Table;
