import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithRedirect,
  signInWithPopup,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";
import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  collection,
  onSnapshot,
  query,
  getDocs,
  where,
  orderBy,
  limit,
  updateDoc,
  addDoc,
} from "firebase/firestore";
import axios from "axios";
import { async } from "@firebase/util";
import Error from "../../src/assets/images/error.png";
import SelfCheck from "../../src/assets/images/self-check.png";
export const instance = axios.create({
  baseURL: 'https://carso.me',
  // baseURL: "http://localhost:3001",
  // timeout: 1000,
  headers: { martech_token: "e5246b1bdaa7c2b1cc3d8f0e65f4c7bec26c3936", platform:'cap' },
});
// // Production
const firebaseConfig = {
    apiKey: "AIzaSyCk8YE7ALk4xMm8BOj0CCoAJLiMshMBzHo",
    authDomain: "projectambassador-prod.firebaseapp.com",
    databaseURL: "https://projectambassador-prod-default-rtdb.firebaseio.com",
    projectId: "projectambassador-prod",
    storageBucket: "projectambassador-prod.appspot.com",
    messagingSenderId: "5047764515",
    appId: "1:5047764515:web:1a7c66d2b19ecc84771396"
};

// Stage
// const firebaseConfig = {
//     apiKey: "AIzaSyA2ZtVcZaEESHfoB_oJPE_VWngeiAATgLM",
//     authDomain: "carsome-ambassador-stage.firebaseapp.com",
//     projectId: "carsome-ambassador-stage",
//     storageBucket: "carsome-ambassador-stage.appspot.com",
//     messagingSenderId: "830829931991",
//     appId: "1:830829931991:web:8403504de2d1f9a006ef92"
// };

// Initialize Firebase
const unsubscribeOnSignOut = [];
export const app = initializeApp(firebaseConfig);

const provider = new GoogleAuthProvider();

provider.setCustomParameters({
  prompt: "select_account",
  // hd: "carsome.com",
});

export const auth = getAuth();
export const signInWithGooglePopup = () => signInWithPopup(auth, provider);
export const signInWithGoogleRedirect = () => signInWithRedirect(auth, provider);
export const db = getFirestore();

export const createUserDocumentFromAuth = async (userAuth, additionalInformation = {}) => {
  const userDocRef = doc(db, "users", userAuth.uid);
  const userSnapshot = await getDoc(userDocRef);
  const userActivityRef = collection(db, "activity_logs");
  const createdAt = new Date();
  const lastLoginAt = new Date();
  const { displayName, email } = userAuth;
  if (!userSnapshot.exists()) {
 
    try {
      await setDoc(userDocRef, {
        displayName,
        email,
        createdAt,
        lastLoginAt,
        ...additionalInformation,
      });
      await addDoc(userActivityRef, {
        uid: userAuth.uid,
        displayName,
        email,
        createdAt,
        lastLoginAt,
        ...additionalInformation,
      });
    } catch (error) {
      console.log("error while creating document", error.message);
    }
  } else {
    try {
      await updateDoc(userDocRef, {
        lastLoginAt,
        ...additionalInformation,
      });
      await addDoc(userActivityRef, {
        uid: userAuth.uid,
        displayName,
        email,
        createdAt,
        lastLoginAt,
        ...additionalInformation,
      });
    } catch (error) {
      console.log("error while creating document", error.message);
    }
    window.analytics.identify(userAuth.uid, {
        name: userAuth.displayName,
        email: userAuth.email,
    });

    window.analytics.track("Signin Success", {
        "path": window.location.host + '' + window.location.pathname,
        "url": window.location.href,
    });
  }
};

export const createAuthUserWithEmailAndPassword = async (email, password) => {
  if (!email || !password) return;

  return await createUserWithEmailAndPassword(auth, email, password);
};

export const signInAuthUserWithEmailAndPassword = async (email, password) => {
  if (!email || !password) return;

  return await signInWithEmailAndPassword(auth, email, password);
};

export const signOutUser = () => {
  unsubscribeOnSignOut.forEach((unsubscribe) => {
    unsubscribe();
  });
  sessionStorage.removeItem("lastLoginAt");
  signOut(auth);
};

export const onAuthStateChangedListener = (callback) => {
  return onAuthStateChanged(auth, callback);
};

export const getRegion = (link) => {
  var url = new URL(link);
  var region = url.hostname.split(".")[2];
  return region;
};

export const getCategory = (link) => {
  var sell_car = "/sell-car";
  var buy_car = "/buy-car";
  var url = new URL(link);
  var location = url.pathname;
  if (location.startsWith(sell_car)) {
    location = "c2b";
  } else if (location.startsWith(buy_car)) {
    location = "b2c";
  } else {
    location = "gen";
  }
  return location;
};

// link generation methods
export const createShortenLink = async (payload) => {
  console.log(payload)
    const region = getRegion(payload.link);
    const category = getCategory(payload.link);
    const utm_source = "ambassador"
    const utm_medium = "affiliate"
    const utm_campaign = `${region}-${category}-mu-conv`
    const utm_content = payload.uid ? payload.uid : payload.user_id;
    const utm_term = payload.ref1? encodeURI(payload.ref1) : "";
    const user_name = encodeURI(payload.user_name);
    const user_email = payload.user_email;
    const encodedLink = `${payload.link}?utm_source=${utm_source}&utm_medium=${utm_medium}&utm_campaign=${utm_campaign}&utm_content=${utm_content}&utm_term=${utm_term}&user_name=${user_name}&user_email=${user_email}`;
    // const {data} = await axios.post(`https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${firebaseConfig.apiKey}`,{
   
    // // Prod
    //     longDynamicLink: `https://csome.page.link/?link=${encodedLink}`

    //     // Stage
    //     // longDynamicLink: `https://cstage.page.link/?link=${encodedLink}`
    // })
    const {data} = await instance.post("/api/onelink/create", { long_url:encodedLink, user_id:payload.user_id, name:utm_campaign, type:'cap', user_name: payload.user_name, user_email: payload.user_email });

        // const {data} = await axios.post(`https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${firebaseConfig.apiKey}`,{
    //     // Prod
    //     longDynamicLink: `https://csome.page.link/?link=${encodedLink}`

    //     // Stage
    //     // longDynamicLink: `https://cstage.page.link/?link=${encodedLink}`
    // })
    if(!data||!data?.data?.onelink){
        throw new Error("Didn't get shorten link")
    }
  if (!data||!data?.data?.onelink) {
    throw new Error("Didn't get shorten link");
  }
  return data?.data?.onelink;
};

export const onUserReferralsStateChangedListener = async (uid, callback) => {
  const q = query(collection(db, "users", uid, "purchases"));

  const purchaseData = await getDocs(q);
  const purchases = [];
  purchaseData.forEach((doc) => {
    let docData = doc.data();
    purchases.push({
      id: docData.id,
      ...docData,
      createdAt: docData.createdAt.toDate(),
    });
  });
  callback(purchases);
};

export const onUserConversionListener = async (uid, callback) => {
  const q = query(collection(db, "users", uid, "conversion"));
  let querySnapshot = await getDocs(q);
  callback(querySnapshot.size);
};

export const onUserClicksListener = async (uid, callback) => {
  const q = query(collection(db, "users", uid, "clicks"), orderBy("createdAt", "desc"), limit(100));
  const userClickData = await getDocs(q);
  const user_clicks_data = [];
  userClickData.forEach((doc) => {
    let docObject = doc.data();
    let click_date = new Date(docObject.createdAt.toDate());
    user_clicks_data.push({
      clickCategory: docObject.clickCategory,
      referrer: docObject.referrer,
      createdAt: click_date.toLocaleString(),
      reference: docObject.reference,
      referral_link: docObject.page_path,
    });
  });
  callback(user_clicks_data);
};

export const onUserStateChangeListener = async (uid, callback) => {
  const q = query(doc(db, "users", uid));
  let userSnapshot = await getDoc(q);
  callback(userSnapshot.data());
};



// ************************* Current User Journey Dashboard ************************

// C2B Current User
export const getWholesaleCurrentUserJourney = (rowData, uid) => {
  let c2b_user_journey = [];
  rowData.forEach((row, index) => {
    let element_position = c2b_user_journey.findIndex((x) => x.t_id === row.tId);
    if (element_position !== -1) {
      c2b_user_journey[element_position][row.event] = <img className="user-journey" title="Transaction is completed." src={SelfCheck} alt="Transaction Completed"/>;
      let event_timestamp = row.event + "_timestamp";
      c2b_user_journey[element_position][event_timestamp] = row.createdAt.toDate().toLocaleString();
    } else {
      const temp = {};
      temp["t_id"] = row.tId;
      temp["cap_id"] = row.cap_id;
      temp["reference"] = row.reference;
      temp["country"] = row.country;
      let event_timestamp = row.event + "_timestamp";
      temp[event_timestamp] = row.createdAt.toDate().toLocaleString();
      temp[row.event] = <img className="user-journey" title="Transaction is completed." src={SelfCheck} alt="Transaction Completed" />;
      c2b_user_journey.push(temp);
    }
  });

  const finalUserJourneyArr = [];
  const onlyOfflineEvents = [];
  c2b_user_journey.forEach((row) => {
    if (row.lead || row.online_appointment) {
      finalUserJourneyArr.push(row);
    } else {
      onlyOfflineEvents.push(row);
    }
  });

  return finalUserJourneyArr;
};

export const concatCurrentWholesaleEvents = async (uid)=>{
  const leads = [];
  const online_appointments = [];
  const inspections = [];
  const purchases = [];

  const leads_query = query(collection(db, "c2b", "1gjLqceCCNSupiYdINxx", "leads"), where("cap_id", "==", uid));
  const online_appointment_query = query(collection(db, "c2b", "1gjLqceCCNSupiYdINxx", "online_appointments"),where("cap_id", "==", uid));
  const inspection_query = query(collection(db, "c2b", "1gjLqceCCNSupiYdINxx", "inspections"));
  const purchase_query = query(collection(db, "c2b", "1gjLqceCCNSupiYdINxx", "purchases"));

  let leadSnapshot = await getDocs(leads_query);
  leadSnapshot.forEach((doc) => {
    leads.push(doc.data());
  });

  let onlineAppointmentSnapshot = await getDocs(online_appointment_query);
  onlineAppointmentSnapshot.forEach((doc) => {
    online_appointments.push(doc.data());
  });

  let inspectionSnapshot = await getDocs(inspection_query);
  inspectionSnapshot.forEach((doc) => {
    inspections.push(doc.data());
  });

  let purchaseSnapshot = await getDocs(purchase_query);
  purchaseSnapshot.forEach((doc) => {
    purchases.push(doc.data());
  });

  const wholesale_list = leads.concat(online_appointments, inspections, purchases);
  return wholesale_list;
}

export const onUserC2BClicksListener = async (uid, callback) => {
  const q = query(collection(db, "users", uid, "clicks"));
  let querySnapshot = await getDocs(q);
  callback(querySnapshot.size);
};

export const onUserC2BLeadsListener = async (uid, callback) => {
  const q = query(collection(db, "c2b", "1gjLqceCCNSupiYdINxx", "leads"), where("cap_id", "==", uid));
  let querySnapshot = await getDocs(q);
  callback(querySnapshot.size);
};

export const onUserC2BOnlineAppointmentsListener = async (uid, callback) => {
  const q = query(collection(db, "c2b", "1gjLqceCCNSupiYdINxx", "online_appointments"), where("cap_id", "==", uid));
  let querySnapshot = await getDocs(q);
  callback(querySnapshot.size);
};

export const onUserC2BInspectionsListener = async (uid, callback) => {
  let c2b_current_events = await concatCurrentWholesaleEvents(uid);
  const final_wholesale_list = getWholesaleCurrentUserJourney(c2b_current_events, uid);
  let current_user_events_count = final_wholesale_list.filter((obj) => {
    return obj.inspection;
  });
  callback(current_user_events_count.length);
};

export const onUserC2BPurchasesListener = async (uid, callback) => {
  let c2b_current_events = await concatCurrentWholesaleEvents(uid);
  const final_wholesale_list = getWholesaleCurrentUserJourney(c2b_current_events, uid);
  let current_user_events_count = final_wholesale_list.filter((obj) => {
    return obj.purchase;
  });
  callback(current_user_events_count.length);
};

export const onUserC2BWholesaleCurrentUserListListener = async (uid, callback) => {
  let c2b_current_events = await concatCurrentWholesaleEvents(uid);
  const final_wholesale_list = getWholesaleCurrentUserJourney(c2b_current_events, uid);
  callback(final_wholesale_list);
};



// B2C Current User
export const concatCurrentRetailEvents = async(uid)=>{
  const registrations = [];
  const appointments = [];
  const online_bookings = [];
  const offline_order = [];
  const test_drive = [];
  const delivery = [];

  const registration_query = query(collection(db, "b2c", "8Xdp7CKFwZXByda30G2Y", "registrations"), where("cap_id", "==", uid));
  const appointments_query = query(collection(db, "b2c", "8Xdp7CKFwZXByda30G2Y", "appointments"), where("cap_id", "==", uid));
  const online_bookings_query = query(collection(db, "b2c", "8Xdp7CKFwZXByda30G2Y", "online_bookings"), where("cap_id", "==", uid));
  const offline_order_query = query(collection(db, "b2c", "8Xdp7CKFwZXByda30G2Y", "offline_order"));
  const test_drive_query = query(collection(db, "b2c", "8Xdp7CKFwZXByda30G2Y", "test_drive"));
  const delivery_query = query(collection(db, "b2c", "8Xdp7CKFwZXByda30G2Y", "delivery"));

  let registrationSnapshot = await getDocs(registration_query);
  registrationSnapshot.forEach((doc) => {
    registrations.push(doc.data());
  });

  let appointmentSnapshot = await getDocs(appointments_query);
  appointmentSnapshot.forEach((doc) => {
    appointments.push(doc.data());
  });

  let onlineBookingSnapshot = await getDocs(online_bookings_query);
  onlineBookingSnapshot.forEach((doc) => {
    online_bookings.push(doc.data());
  });

  let offlineOrderSnapshot = await getDocs(offline_order_query);
  offlineOrderSnapshot.forEach((doc) => {
    offline_order.push(doc.data());
  });

  let testDriveSnapshot = await getDocs(test_drive_query);
  testDriveSnapshot.forEach((doc) => {
    test_drive.push(doc.data());
  });

  let deliverySnapshot = await getDocs(delivery_query);
  deliverySnapshot.forEach((doc) => {
    delivery.push(doc.data());
  });

  const retail_list = registrations.concat(appointments, online_bookings, offline_order, test_drive, delivery);
  return retail_list;
}

const getEventName = (event) => {
  let event_name;
  if (event === "Turn Up") {
    event_name = "turn_up";
  } else if (event === "Test Drive") {
    event_name = "test_drive";
  } else if (event === "Offline Order") {
    event_name = "offline_order";
  } else if (event === "Delivery") {
    event_name = "delivery";
  } else if (event === "Online Order") {
    event_name = "online_order";
  } else {
    event_name = event;
  }

  return event_name;
};

export const getUserJourney = (uid, data) => {
  const userJourney = [];
  data.forEach((meta) => {
    const temp = {};
    if (meta.cap_id && meta.cap_id === uid) {
      temp["b2c_uid"] = meta.b2c_uid;
      temp["cap_id"] = meta.cap_id;
      temp["car_id"] = meta.car_id;
      temp["country"] = meta.product_country ? meta.product_country : 'MY'
      temp["event"] = getEventName(meta.event);
      let refer_name = temp["event"] + "_reference";
      temp[refer_name] = meta.reference;
      let event_timestamp = temp["event"] + "_timestamp";
      temp[event_timestamp] = meta.createdAt;
      if (!(meta.b2c_uid in userJourney)) {
        userJourney[meta.b2c_uid] = [];
      }
      if (meta.car_id){
        if (!(meta.car_id in userJourney[meta.b2c_uid])) {
          userJourney[meta.b2c_uid][meta.car_id] = [];
        }
        userJourney[meta.b2c_uid][meta.car_id].push(temp);
      }
      // else if(meta.car_id === undefined && meta.event === "registration"){
      //   userJourney[meta.b2c_uid]['registration'] = [];
      //   const temp_orders = {};
      //   temp_orders["b2c_uid"] = meta.b2c_uid;
      //   temp_orders["cap_id"] = meta.cap_id;
      //   temp_orders["car_id"] = meta.car_id;
      //   temp_orders["event"] = "registration";
      //   let refer_name = temp_orders["event"] + "_reference";
      //   temp_orders[refer_name] = meta.reference;
      //   let event_cap_id = temp_orders["event"] + "_cap_id";
      //   temp_orders[event_cap_id] = meta.cap_id;
      //   let event_timestamp = temp_orders["event"] + "_timestamp";
      //   temp_orders[event_timestamp] = meta.createdAt;
      //   userJourney[meta.b2c_uid]['registration'].push(temp_orders);
      // } 
    }
    if (meta.event === "Offline Order") {
      const temp_orders = {};
      temp_orders["b2c_uid"] = meta.b2c_uid;
      temp_orders["cap_id"] = meta.cap_id;
      temp_orders["car_id"] = meta.car_id;
      temp_orders["event"] = "offline_order";
      temp["country"] = meta.product_country ? meta.product_country : 'MY'
      let refer_name = temp_orders["event"] + "_reference";
      temp_orders[refer_name] = meta.reference;
      let event_timestamp = temp_orders["event"] + "_timestamp";
      temp_orders[event_timestamp] = meta.createdAt;
      if (userJourney[meta.b2c_uid] !== undefined) {
        if (userJourney[meta.b2c_uid][meta.car_id] !== undefined) {
          userJourney[meta.b2c_uid][meta.car_id].push(temp_orders);
        }
      }
    }else if (meta.event === "Delivery") {
      const temp = {};
      temp["b2c_uid"] = meta.b2c_uid;
      temp["cap_id"] = meta.cap_id;
      temp["car_id"] = meta.car_id;
      temp["event"] = "delivery";
      temp["country"] = meta.product_country ? meta.product_country : 'MY'
      let refer_name = temp["event"] + "_reference";
      temp[refer_name] = meta.reference;
      let event_timestamp = temp["event"] + "_timestamp";
      temp[event_timestamp] = meta.createdAt;
      if (userJourney[meta.b2c_uid] !== undefined) {
        if (userJourney[meta.b2c_uid][meta.car_id] !== undefined) {
          userJourney[meta.b2c_uid][meta.car_id].push(temp);
        }
      }
    }else if (meta.event === "Turn Up") {
      const temp = {};
      temp["b2c_uid"] = meta.b2c_uid;
      temp["cap_id"] = meta.cap_id;
      temp["car_id"] = meta.car_id;
      temp["event"] = "turn_up";
      temp["country"] = meta.product_country ? meta.product_country : 'MY'
      let refer_name = temp["event"] + "_reference";
      temp[refer_name] = meta.reference;
      let event_timestamp = temp["event"] + "_timestamp";
      temp[event_timestamp] = meta.createdAt;
      if (userJourney[meta.b2c_uid] !== undefined) {
        if (userJourney[meta.b2c_uid][meta.car_id] !== undefined) {
          userJourney[meta.b2c_uid][meta.car_id].push(temp);
        }
      }
    }else if (meta.event === "Test Drive") {
      const temp = {};
      temp["b2c_uid"] = meta.b2c_uid;
      temp["cap_id"] = meta.cap_id;
      temp["car_id"] = meta.car_id;
      temp["event"] = "test_drive";
      temp["country"] = meta.product_country ? meta.product_country : 'MY'
      let refer_name = temp["event"] + "_reference";
      temp[refer_name] = meta.reference;
      let event_timestamp = temp["event"] + "_timestamp";
      temp[event_timestamp] = meta.createdAt;
      if (userJourney[meta.b2c_uid] !== undefined) {
        if (userJourney[meta.b2c_uid][meta.car_id] !== undefined) {
          userJourney[meta.b2c_uid][meta.car_id].push(temp);
        }
      }
    }
  });
  
  // const getUserNestedKeys = (data)=>{
  //   let tempObj = []  
  //   for (let i in data){
  //     if(i != 'registration')
  //       tempObj.push(i)
  //   }
  //   return tempObj;
  // }

  let userJourneyArr = [];
  for (let key in userJourney) {
    for (let nested_key in userJourney[key]) {
      let obj = userJourney[key][nested_key];
      let tempObj = {};
      
      // if(userJourney[key]['registration'] != undefined){
      //   // Get User car keys
      //   const nested_user_keys = getUserNestedKeys(userJourney[key]);

      //   // Append Registration object to existing car array
      //   nested_user_keys.forEach((str)=>{     
      //       userJourney[key]['registration'][0].car_id  = str
      //       let reg_obj = {}
      //       reg_obj['b2c_uid'] = userJourney[key]['registration'][0].b2c_uid
      //       reg_obj['cap_id'] = userJourney[key]['registration'][0].cap_id
      //       reg_obj['car_id'] = str
      //       reg_obj['event'] = userJourney[key]['registration'][0].event
      //       reg_obj['registration_reference'] = userJourney[key]['registration'][0].registration_reference
      //       reg_obj['registration_timestamp'] = userJourney[key]['registration'][0].registration_timestamp
      //       userJourney[key][str].push(reg_obj)
      //   })
      // }

      // delete userJourney[key]['registration']

      // Prepare the single user car journey
      obj.forEach((r) => {
        tempObj["b2c_uid"] = r.b2c_uid;
        tempObj["car_id"] = r.car_id;
        tempObj["cap_id"] = r.cap_id;
        tempObj["country"] = r.country
        let ref_name = r.event + "_reference";
        tempObj[ref_name] = r[ref_name];
        let event_timestamp = r.event + "_timestamp";
        tempObj[event_timestamp] = r[event_timestamp] ? r[event_timestamp].toDate().toLocaleString() : r[event_timestamp];
        tempObj[r.event] = r.event ? <img className="user-journey" title="Transaction is completed." src={SelfCheck} alt="Transaction Completed"/> : <img className="user-journey" title="Transaction not intiated." src={Error} alt="Transaction Failed" />;
      });
      userJourneyArr.push(tempObj);
    }
  }

          const registration_arr = data.filter((obj) => {
            return obj.event === "registration";
          });
          let currentUserRegistrations = registration_arr.filter((obj) => {
            return obj.cap_id === uid;
          });
          if (currentUserRegistrations.length) {
            currentUserRegistrations.forEach((key, idx) => {
              for (let j = 0; j < userJourneyArr.length; j++) {
                if (key.b2c_uid === userJourneyArr[j].b2c_uid) {
                  userJourneyArr[j]["registration"] = (
                    <img
                      className="user-journey"
                      title="Transaction is completed."
                      src={SelfCheck}
                      alt="Transaction Completed"
                    />
                  );
                  userJourneyArr[j]["registration_reference"] = key.reference;
                  userJourneyArr[j]["registration_timestamp"] = key.createdAt.toDate().toLocaleString();
                }
              }
            });
          }
  return userJourneyArr;
};

export const onUserB2CClicksListener = async (uid, callback) => {
  const q = query(collection(db, "users", uid, "clicks"), where("clickCategory", "==", "B2C"));
  let querySnapshot = await getDocs(q);
  callback(querySnapshot.size);
};

export const onUserB2CRegsitrationsListener = async (uid, callback) => {
  const q = query(collection(db, "b2c", "8Xdp7CKFwZXByda30G2Y", "registrations"), where("cap_id", "==", uid));
  let querySnapshot = await getDocs(q);
  callback(querySnapshot.size);
};

export const onUserB2CAppointmentsListener = async (uid, callback) => {
  const q = query(collection(db, "b2c", "8Xdp7CKFwZXByda30G2Y", "appointments"), where("cap_id", "==", uid));
  let querySnapshot = await getDocs(q);
  callback(querySnapshot.size);
};

export const onUserB2COnlineBookingsListener = async (uid, callback) => {
  let current_retail_events = await concatCurrentRetailEvents(uid);
  let result_arr = getUserJourney(uid, current_retail_events);
  let current_user_events_count = result_arr.filter((obj) => {
    return obj.online_booking;
  });
  callback(current_user_events_count.length);
};

export const onUserB2COrdersListener = async (uid, callback) => {
  let current_retail_events = await concatCurrentRetailEvents(uid);
  let result_arr = getUserJourney(uid, current_retail_events);
  let current_user_events_count = result_arr.filter((obj) => {
    return obj.offline_order;
  });
  callback(current_user_events_count.length);
};

export const onUserB2CDeliveryListener = async (uid, callback) => {
  let current_retail_events = await concatCurrentRetailEvents(uid);
  let result_arr = getUserJourney(uid, current_retail_events);
  let current_user_events_count = result_arr.filter((obj) => {
    return obj.delivery;
  });
  callback(current_user_events_count.length);
};

export const onUserB2CTestDriveListener = async (uid, callback) => {
  let current_retail_events = await concatCurrentRetailEvents(uid);
  let result_arr = getUserJourney(uid, current_retail_events);
  let current_user_events_count = result_arr.filter((obj) => {
    return obj.test_drive;
  });
  callback(current_user_events_count.length);
};

export const onUserB2CRetailListListener = async (uid, callback) => {
  let current_retail_events = await concatCurrentRetailEvents(uid);
  const b2c_user_journey = getUserJourney(uid, current_retail_events);
  callback(b2c_user_journey);
};



// ************************************* Admin Dashboard ***********************************

// B2C ALL
export const getAllUserJourney = (data) => {
  const userJourney = [];

  data.forEach((meta) => {
    const temp = {};
    temp["b2c_uid"] = meta.b2c_uid;
    temp["cap_id"] = meta.cap_id;
    temp["car_id"] = meta.car_id;
    temp["event"] = getEventName(meta.event);
    temp["country"] = meta.product_country ? meta.product_country : 'MY'
    let event_cap_id = temp["event"] + "_cap_id";
    temp[event_cap_id] = meta.cap_id;
    let refer_name = temp["event"] + "_reference";
    temp[refer_name] = meta.reference;
    let event_timestamp = temp["event"] + "_timestamp";
    temp[event_timestamp] = meta.createdAt;
    if (!(meta.b2c_uid in userJourney)) {
      userJourney[meta.b2c_uid] = [];
    }
    if (meta.car_id) {
      if (!(meta.car_id in userJourney[meta.b2c_uid])) {
        userJourney[meta.b2c_uid][meta.car_id] = [];
      }
      userJourney[meta.b2c_uid][meta.car_id].push(temp);
    }
    // else if(meta.car_id === undefined && meta.event === "registration"){
    //   userJourney[meta.b2c_uid]['registration'] = [];
    //   const temp_orders = {};
    //   temp_orders["b2c_uid"] = meta.b2c_uid;
    //   temp_orders["cap_id"] = meta.cap_id;
    //   temp_orders["car_id"] = meta.car_id;
    //   temp_orders["event"] = "registration";
    //   let event_cap_id = temp_orders["event"] + "_cap_id";
    //   temp_orders[event_cap_id] = meta.cap_id;
    //   let event_timestamp = temp_orders["event"] + "_timestamp";
    //   temp_orders[event_timestamp] = meta.createdAt;
    //   userJourney[meta.b2c_uid]['registration'].push(temp_orders);
    // }
    if (meta.event === "Offline Order") {
      const temp_orders = {};
      temp_orders["b2c_uid"] = meta.b2c_uid;
      temp_orders["cap_id"] = meta.cap_id;
      temp_orders["car_id"] = meta.car_id;
      temp_orders["event"] = "offline_order";
      temp_orders["country"] = meta.product_country ? meta.product_country : 'MY'
      let event_cap_id = temp_orders["event"] + "_cap_id";
      temp_orders[event_cap_id] = meta.cap_id;
      let event_timestamp = temp_orders["event"] + "_timestamp";
      temp_orders[event_timestamp] = meta.createdAt;
      if (userJourney[meta.b2c_uid] !== undefined) {
        if (userJourney[meta.b2c_uid][meta.car_id] !== undefined) {
          userJourney[meta.b2c_uid][meta.car_id].push(temp_orders);
        }
      }
    } else if (meta.event === "Turn Up") {
      const temp_orders = {};
      temp_orders["b2c_uid"] = meta.b2c_uid;
      temp_orders["cap_id"] = meta.cap_id;
      temp_orders["car_id"] = meta.car_id;
      temp_orders["event"] = "turn_up";
      temp_orders["country"] = meta.product_country ? meta.product_country : 'MY'
      let event_cap_id = temp_orders["event"] + "_cap_id";
      temp_orders[event_cap_id] = meta.cap_id;
      let event_timestamp = temp_orders["event"] + "_timestamp";
      temp_orders[event_timestamp] = meta.createdAt;
      if (userJourney[meta.b2c_uid] !== undefined) {
        if (userJourney[meta.b2c_uid][meta.car_id] !== undefined) {
          userJourney[meta.b2c_uid][meta.car_id].push(temp_orders);
        }
      }
    } else if (meta.event === "Test Drive") {
      const temp_orders = {};
      temp_orders["b2c_uid"] = meta.b2c_uid;
      temp_orders["cap_id"] = meta.cap_id;
      temp_orders["car_id"] = meta.car_id;
      temp_orders["event"] = "test_drive";
      temp_orders["country"] = meta.product_country ? meta.product_country : 'MY'
      let event_cap_id = temp_orders["event"] + "_cap_id";
      temp_orders[event_cap_id] = meta.cap_id;
      let event_timestamp = temp_orders["event"] + "_timestamp";
      temp_orders[event_timestamp] = meta.createdAt;
      if (userJourney[meta.b2c_uid] !== undefined) {
        if (userJourney[meta.b2c_uid][meta.car_id] !== undefined) {
          userJourney[meta.b2c_uid][meta.car_id].push(temp_orders);
        }
      }
    } else if (meta.event === "Delivery") {
      const temp_orders = {};
      temp_orders["b2c_uid"] = meta.b2c_uid;
      temp_orders["cap_id"] = meta.cap_id;
      temp_orders["car_id"] = meta.car_id;
      temp_orders["event"] = "delivery";
      temp_orders["country"] = meta.product_country ? meta.product_country : 'MY'
      let event_cap_id = temp_orders["event"] + "_cap_id";
      temp_orders[event_cap_id] = meta.cap_id;
      let event_timestamp = temp_orders["event"] + "_timestamp";
      temp_orders[event_timestamp] = meta.createdAt;
      if (userJourney[meta.b2c_uid] !== undefined) {
        if (userJourney[meta.b2c_uid][meta.car_id] !== undefined) {
          userJourney[meta.b2c_uid][meta.car_id].push(temp_orders);
        }
      }
    }
  });

  let userJourneyArr = [];
  for (let key in userJourney) {
    for (let nested_key in userJourney[key]) {
      let obj = userJourney[key][nested_key];
      let tempObj = {};
      obj.forEach(async (r) => {
        tempObj["b2c_uid"] = r.b2c_uid;
        tempObj["car_id"] = r.car_id;
        tempObj["cap_id"] = r.cap_id;
        tempObj["country"] = r.country;
        let ref_name = r.event + "_reference";
        tempObj[ref_name] = r[ref_name];
        let event_timestamp = r.event + "_timestamp";
        tempObj[event_timestamp] = r[event_timestamp]
          ? r[event_timestamp].toDate().toLocaleString()
          : r[event_timestamp];
        tempObj[r.event] = r.event ? (
          <img
            className="user-journey"
            title="Transaction is completed."
            src={SelfCheck}
            alt="Transaction Completed"
          />
        ) : (
          <img
            className="user-journey"
            title="Transaction not intiated."
            src={Error}
            alt="Transaction Failed"
          />
        );
        let event_cap_name = r.event + "_cap_name";
        if (r.cap_id) {
          const q = query(doc(db, "users", r.cap_id));
          let userSnapshot = await getDoc(q);
          const userObj = userSnapshot.data();

          if (userObj !== undefined) {
            tempObj[event_cap_name] = (
              <div>
                {userObj.displayName}, {userObj.email}
              </div>
            );
          }
        }
      });
      
      userJourneyArr.push(tempObj);
    }
  }


  const registration_arr = data.filter((obj) => {
    return obj.event === "registration";
  });
  
  registration_arr.forEach(async (key, idx) => {
    for (let j = 0; j < userJourneyArr.length; j++) {
      if (key.b2c_uid == userJourneyArr[j].b2c_uid) {
        userJourneyArr[j]["registration"] = (
          <img
            className="user-journey"
            title="Transaction is completed."
            src={SelfCheck}
            alt="Transaction Completed"
          />
        );
        userJourneyArr[j]["registration_reference"] = key.reference;
        userJourneyArr[j]["registration_timestamp"] = key.createdAt.toDate().toLocaleString();
        const q = query(doc(db, "users", key.cap_id));
        let userSnapshot = await getDoc(q);
        const userObj = userSnapshot.data();
        if (userObj !== undefined) {
          userJourneyArr[j]["registration_cap_name"] = (
            <div>
              {userObj.displayName}, {userObj.email}
            </div>
          );
        }
      }
    }
  });
  const finalUserJourneyArr = [];
  const onlyOfflineEvents = [];
  userJourneyArr.forEach((row) => {
    if (row.appointment || row.registration || row.online_booking) {
      finalUserJourneyArr.push(row);
    } else {
      onlyOfflineEvents.push(row);
    }
  });
  return finalUserJourneyArr;
};

export const concatAllRetailEvents = async ()=>{
  const registrations = [];
  const appointments = [];
  const online_bookings = [];
  const offline_order = [];
  const test_drive = [];
  const delivery = [];

  const registration_query = query(collection(db, "b2c", "8Xdp7CKFwZXByda30G2Y", "registrations"));
  const appointments_query = query(collection(db, "b2c", "8Xdp7CKFwZXByda30G2Y", "appointments"));
  const online_bookings_query = query(collection(db, "b2c", "8Xdp7CKFwZXByda30G2Y", "online_bookings"));
  const offline_order_query = query(collection(db, "b2c", "8Xdp7CKFwZXByda30G2Y", "offline_order"));
  const test_drive_query = query(collection(db, "b2c", "8Xdp7CKFwZXByda30G2Y", "test_drive"));
  const delivery_query = query(collection(db, "b2c", "8Xdp7CKFwZXByda30G2Y", "delivery"));

  let registrationSnapshot = await getDocs(registration_query);
  registrationSnapshot.forEach((doc) => {
    registrations.push(doc.data());
  });

  let appointmentSnapshot = await getDocs(appointments_query);
  appointmentSnapshot.forEach((doc) => {
    appointments.push(doc.data());
  });

  let onlineBookingSnapshot = await getDocs(online_bookings_query);
  onlineBookingSnapshot.forEach((doc) => {
    online_bookings.push(doc.data());
  });

  let offlineOrderSnapshot = await getDocs(offline_order_query);
  offlineOrderSnapshot.forEach((doc) => {
    offline_order.push(doc.data());
  });

  let testDriveSnapshot = await getDocs(test_drive_query);
  testDriveSnapshot.forEach((doc) => {
    test_drive.push(doc.data());
  });

  let deliverySnapshot = await getDocs(delivery_query);
  deliverySnapshot.forEach((doc) => {
    delivery.push(doc.data());
  });

  const all_retail_list = registrations.concat(appointments, online_bookings, offline_order, test_drive, delivery);
  return all_retail_list
}

export const getB2CAllUserAppointment = (dataObj) => {
  let result_arr = getAllUserJourney(dataObj);
  let online_order_count = result_arr.filter((obj) => {
    return obj.appointment;
  });
  return online_order_count.length;
};

export const getB2CAllUserOnlineOrder = (dataObj) => {
  let result_arr = getAllUserJourney(dataObj);
  let online_order_count = result_arr.filter((obj) => {
    return obj.online_booking;
  });
  return online_order_count.length;
};

export const getB2CAllOfflineOrders = (data) => {
  let result = getAllUserJourney(data);
  let event_count = result.filter((obj) => {
    return obj.offline_order;
  });
  return event_count;
};

export const getB2CAllTestDrives = (data) => {
  let result = getAllUserJourney(data);
  let event_count = result.filter((obj) => {
    return obj.test_drive;
  });
  return event_count;
};

export const getB2CAllDelivers = (data) => {
  let result = getAllUserJourney(data);
  let event_count = result.filter((obj) => {
    return obj.delivery;
  });
  return event_count;
};

export const onAllUserB2CRegsitrationsListener = async (uid, callback) => {
  const q = query(collection(db, "b2c", "8Xdp7CKFwZXByda30G2Y", "registrations"));
  let querySnapshot = await getDocs(q);
  callback(querySnapshot.size);
};

export const onAllUserB2CAppointmentsListener = async (uid, callback) => {
  let all_retail_list = await concatAllRetailEvents();
  let result_arr = getAllUserJourney(all_retail_list);
  let online_order_count = result_arr.filter((obj) => {
    return obj.appointment;
  });
  callback(online_order_count.length);
};

export const onAllUserB2COnlineBookingsListener = async (uid, callback) => {
  let all_retail_list = await concatAllRetailEvents();
  let result_arr = getAllUserJourney(all_retail_list);
  let online_order_count = result_arr.filter((obj) => {
    return obj.online_booking;
  });
  callback(online_order_count.length);
};

export const onAllUserB2COrdersListener = async (uid, callback) => {
  let all_retail_list = await concatAllRetailEvents();
  let result = getAllUserJourney(all_retail_list);
  let event_count = result.filter((obj) => {
    return obj.offline_order;
  });
  callback(event_count.length);
};

export const onAllUserB2CTestDriveListener = async (uid, callback) => {
  let all_retail_list = await concatAllRetailEvents();
  let result = getAllUserJourney(all_retail_list);
  let event_count = result.filter((obj) => {
    return obj.test_drive;
  });
  callback(event_count.length);
};

export const onAllUserB2CDeliveryListener = async (uid, callback) => {
  let all_retail_list = await concatAllRetailEvents();
  let result = getAllUserJourney(all_retail_list);
  let event_count = result.filter((obj) => {
    return obj.delivery;
  });
  callback(event_count.length);
};

export const onUserB2CRetailAllUserJourneyListListener = async (uid, callback) => {
  let all_retail_list = await concatAllRetailEvents();
  const b2c_user_journey = getAllUserJourney(all_retail_list);
  callback(b2c_user_journey);
};

// C2B ALL
const getWholesaleAllUserJourney = (rowData) => {
  let c2b_user_journey = []
    rowData.forEach((row)=> {
        let element_position = c2b_user_journey.findIndex(x => x.t_id === row.tId);
        if(element_position != -1){
            c2b_user_journey[element_position][row.event] = <img className='user-journey' title="Transaction is completed." src={SelfCheck}/>
            let event_cap_id = row.event+"_cap_id"
            c2b_user_journey[element_position][event_cap_id] = row.cap_id
            let event_timestamp = row.event+"_timestamp"
            c2b_user_journey[element_position][event_timestamp] = (row.createdAt) ? row.createdAt.toDate().toLocaleString() : null
            let event_cap_name = row.event+'_cap_name'
            if(row.cap_id){
                const q = query(doc(db, "users", row.cap_id));
                const userQuery = onSnapshot(q, (snapshot)=>{
                    const userObj = snapshot.data();
                    if(userObj != undefined){
                        c2b_user_journey[element_position][event_cap_name] = <div>{userObj.displayName}, {userObj.email}</div>
                    }
                });
            }
        }else{
            const temp = {}
            temp['t_id'] = row.tId;
            let event_cap_id = row.event+"_cap_id"
            let event_timestamp = row.event+"_timestamp"
            temp[event_timestamp] = (row.createdAt) ? row.createdAt.toDate().toLocaleString() : null
            temp[event_cap_id] = row.cap_id
            temp['reference'] = row.reference
            temp['country'] = row.country
            temp[row.event] = <img className='user-journey' title="Transaction is completed." src={SelfCheck}/>;    
            let event_cap_name = row.event+'_cap_name'
            
            if(row.cap_id){
                const q = query(doc(db, "users", row.cap_id));
                const userQuery = onSnapshot(q, (snapshot)=>{
                    const userObj = snapshot.data();
                    if(userObj != undefined){
                        temp[event_cap_name] = <div>{userObj.displayName}, {userObj.email}</div>
                    }
                })
            }
            c2b_user_journey.push(temp);
        }
        
    });

    const finalUserJourneyArr = [];
    const onlyOfflineEvents = [];
    c2b_user_journey.forEach((row) => {
      if (row.lead || row.online_appointment) {
        finalUserJourneyArr.push(row);
      } else {
        onlyOfflineEvents.push(row);
      }
    });

    return finalUserJourneyArr;
};

const concatAllWholesaleEvents = async()=>{
  const leads = [];
    const online_appointments = [];
    const inspections = [];
    const purchases = [];

    const leads_query = query(collection(db, "c2b", "1gjLqceCCNSupiYdINxx", "leads"));
    const online_appointment_query = query(collection(db, "c2b", "1gjLqceCCNSupiYdINxx", "online_appointments"));
    const inspection_query = query(collection(db, "c2b", "1gjLqceCCNSupiYdINxx", "inspections"));
    const purchase_query = query(collection(db, "c2b", "1gjLqceCCNSupiYdINxx", "purchases"));

    let leadSnapshot = await getDocs(leads_query);
    leadSnapshot.forEach((doc) => {
      leads.push(doc.data());
    });

    let onlineAppointmentSnapshot = await getDocs(online_appointment_query);
    onlineAppointmentSnapshot.forEach((doc) => {
      online_appointments.push(doc.data());
    });

    let inspectionSnapshot = await getDocs(inspection_query);
    inspectionSnapshot.forEach((doc) => {
      inspections.push(doc.data());
    });

    let purchaseSnapshot = await getDocs(purchase_query);
    purchaseSnapshot.forEach((doc) => {
      purchases.push(doc.data());
    });

    const wholesale_all_events = leads.concat(online_appointments, inspections, purchases);
    return wholesale_all_events;
}

export const onAllUserC2BLeadsListener = async (uid, callback) => {
  const q = query(collection(db, "c2b", "1gjLqceCCNSupiYdINxx", "leads"));
  let querySnapshot = await getDocs(q);
  callback(querySnapshot.size);
};

export const onAllUserC2BOnlineAppointmentsListener = async (uid, callback) => {
  const q = query(collection(db, "c2b", "1gjLqceCCNSupiYdINxx", "online_appointments"));
  let querySnapshot = await getDocs(q);
  callback(querySnapshot.size);
};

export const onAllUserC2BInspectionsListener = async (uid, callback) => {
  const wholesale_list = await concatAllWholesaleEvents();
  const result_arr = getWholesaleAllUserJourney(wholesale_list);
  let total_inspections = result_arr.filter((obj) => {
    return obj.inspection;
  });
  callback(total_inspections.length);
};

export const onAllUserC2BPurchasesListener = async (uid, callback) => {
  const wholesale_list = await concatAllWholesaleEvents();
  const result_arr = getWholesaleAllUserJourney(wholesale_list);
  let total_inspections = result_arr.filter((obj) => {
    return obj.purchase;
  });
  callback(total_inspections.length);
};

export const onUserC2BWholesaleAllUserListListener = async (uid, callback) => {
    const wholesale_list = await concatAllWholesaleEvents();
    const final_wholesale_list = getWholesaleAllUserJourney(wholesale_list);
    callback(final_wholesale_list);
};





