import React, { useMemo } from "react";
import MaterialReactTable from "material-react-table";
import { Box } from "@mui/material";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Tooltip from '@mui/material/Tooltip';
import { ExportToCsv } from 'export-to-csv'; 



const RetailCurrentUserJourney = ({ schema = "", data = [], isLoading }) => {

  const columns = useMemo(
    () => [
      {
        accessorKey: "b2c_uid", //access nested data with dot notation
        header: "User ID",
        size: 200,
      },
      {
        accessorKey: "car_id",
        header: "Car ID",
        size: 150,
      },
      {
        accessorKey: "country",
        header: "Country",
        size: 100,
      },
      {
        accessorKey: "registration_reference", //normal accessorKey
        header: "Registration Reference",
      },
      {
        accessorFn: (row) => `${row?.registration?.props?.src}`, //accessorFn used to join multiple data into a single cell
        id: "registration", //id is still required when using accessorFn instead of accessorKey
        header: "Registration",
        size: 100,
        Cell: ({ cell, row }) => (
          <Box
            sx={{
              marginLeft: "30px"
            }}
          >
            {row?.original?.registration?.props?.src ? (
              <Tooltip title={row.original.registration.props.title}>
                <img
                  alt="avatar"
                  height={15}
                  src={row.original.registration.props.src}
                  loading="lazy"
                  style={{ borderRadius: "50%" }}
                />
              </Tooltip>
            ) : (
              ""
            )}
          </Box>
        ),
      },
      {
        accessorKey: "registration_timestamp", //normal accessorKey
        header: "Registration Created At",
      },
      {
        accessorKey: "appointment_reference", //normal accessorKey
        header: "Appointment Reference",
      },
      {
        accessorFn: (row) => `${row?.appointment?.props?.src}`, //accessorFn used to join multiple data into a single cell
        id: "appointment", //id is still required when using accessorFn instead of accessorKey
        header: "Appointment",
        size: 100,
        Cell: ({ cell, row }) => (
          <Box
            sx={{
              marginLeft: "30px"
            }}
          >
            {row?.original?.appointment?.props?.src ? (
              <Tooltip title={row.original.appointment.props.title}>
                <img
                  alt="avatar"
                  height={15}
                  src={row.original.appointment.props.src}
                  loading="lazy"
                  style={{ borderRadius: "50%" }}
                />
              </Tooltip>
            ) : (
              ""
            )}
            
          </Box>
        ),
      },
      {
        accessorKey: "appointment_timestamp", //normal accessorKey
        header: "Appointment Created At",
      },
      {
        accessorKey: "online_booking_reference", //normal accessorKey
        header: "Online Order Reference",
      },
      {
        accessorFn: (row) => `${row?.online_booking?.props?.src}`, //accessorFn used to join multiple data into a single cell
        id: "onlinebooking", //id is still required when using accessorFn instead of accessorKey
        header: "Online Order",
        size: 100,
        Cell: ({ cell, row }) => (
          <Box
            sx={{
              marginLeft: "30px"
            }}
          >
            {row?.original?.online_booking?.props?.src ? (
              <Tooltip title={row.original.online_booking.props.title}>
                <img
                  alt="avatar"
                  height={15}
                  src={row.original.online_booking.props.src}
                  loading="lazy"
                  style={{ borderRadius: "50%" }}
                />
              </Tooltip>
            ) : (
              ""
            )}
            
          </Box>
        ),
      },
      {
        accessorKey: "online_booking_timestamp", //normal accessorKey
        header: "Online Order Created At",
        size: 200,
      },
      {
        accessorFn: (row) => `${row?.offline_order?.props?.src}`, //accessorFn used to join multiple data into a single cell
        id: "offline_order", //id is still required when using accessorFn instead of accessorKey
        header: "Offline Order",
        size: 100,
        Cell: ({ cell, row }) => (
          <Box
            sx={{
              marginLeft: "30px"
            }}
          >
            {row?.original?.offline_order?.props?.src ? (
              <Tooltip title={row.original.offline_order.props.title}>
                <img
                  alt="avatar"
                  height={15}
                  src={row.original.offline_order.props.src}
                  loading="lazy"
                  style={{ borderRadius: "50%" }}
                />
              </Tooltip>
            ) : (
              ""
            )}
            
          </Box>
        ),
      },
      {
        accessorKey: "offline_order_timestamp", //normal accessorKey
        header: "Offline Order Created At",
        size: 200,
      },
      {
        accessorFn: (row) => `${row?.turn_up?.props?.src}`, //accessorFn used to join multiple data into a single cell
        id: "turn_up", //id is still required when using accessorFn instead of accessorKey
        header: "Turn Up",
        size: 100,
        Cell: ({ cell, row }) => (
          <Box
            sx={{
              marginLeft: "30px"
            }}>
            {row?.original?.turn_up?.props?.src ? (
              <Tooltip title={row.original.turn_up.props.title}>
                <img
                  alt="avatar"
                  height={15}
                  src={row.original.turn_up.props.src}
                  loading="lazy"
                  style={{ borderRadius: "50%" }}
                />
              </Tooltip>
            ) : (
              ""
            )}

          </Box>
        ),
      },
      {
        accessorKey: "turn_up_timestamp", //normal accessorKey
        header: "Turn Up Created At",
      },
      {
        accessorFn: (row) => `${row?.test_drive?.props?.src}`, //accessorFn used to join multiple data into a single cell
        id: "test_drive", //id is still required when using accessorFn instead of accessorKey
        header: "Test Drive",
        size: 100,
        Cell: ({ cell, row }) => (
          <Box
            sx={{
              marginLeft: "30px"
            }}>
            {row?.original?.test_drive?.props?.src ? (
              <Tooltip title={row.original.test_drive.props.title}>
                <img
                  alt="avatar"
                  height={15}
                  src={row.original.test_drive.props.src}
                  loading="lazy"
                  style={{ borderRadius: "50%" }}
                />
              </Tooltip>
            ) : (
              ""
            )}
            
          </Box>
        ),
      },
      {
        accessorKey: "test_drive_timestamp", //normal accessorKey
        header: "Test Drive Created At",
      },
      {
        accessorFn: (row) => `${row?.delivery?.props?.src}`, //accessorFn used to join multiple data into a single cell
        id: "delivery", //id is still required when using accessorFn instead of accessorKey
        header: "Delivery",
        size: 100,
        Cell: ({ cell, row }) => (
          <Box
            sx={{
              marginLeft: "30px"
            }}>
            {row?.original?.delivery?.props?.src ? (
              <Tooltip title={row.original.delivery.props.title}>
                <img
                  alt="avatar"
                  height={15}
                  src={row.original.delivery.props.src}
                  loading="lazy"
                  style={{ borderRadius: "50%" }}
                />
              </Tooltip>
            ) : (
              ""
            )}
            
          </Box>
        ),
      },
      {
        accessorKey: "delivery_timestamp", //normal accessorKey
        header: "Delivery Created At",
      },
    ],
    []
  );

  const csvOptions = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    useBom: true,
    showTitle: true,
    title: 'Retail User Journey',
    filename:'retail-user-journey',
    useKeysAsHeaders: false,
    headers: columns.map((c) => c.header),
  };
  
  const csvExporter = new ExportToCsv(csvOptions);
  const handleExportRows = (rows) => {
    csvExporter.generateCsv(rows.map((row) =>  {
      const data = {
        b2c_uid: row?.original?.b2c_uid ? row?.original?.b2c_uid : "",
        car_id: row?.original?.car_id ? row?.original?.car_id : "",
        country: row?.original?.country ? row?.original?.country : "",
        registration_reference: row?.original?.registration_reference ? row?.original?.registration_reference : "",
        registration: row?.original?.registration?.props?.src ? "yes" : "",
        registration_timestamp: row?.original?.registration_timestamp ? row?.original?.registration_timestamp : "",
        regipname: row?.original?.registration_cap_name?.props?.children
          ? `${row?.original?.registration_cap_name?.props?.children[0]}` + `,` + `${row?.original?.registration_cap_name?.props?.children[2]}`
          : "",
        appointment_reference: row?.original?.appointment_reference ? row?.original?.appointment_reference : "",
        appointment: row?.original?.appointment?.props?.src ? "yes" : "",
        appointment_timestamp: row?.original?.appointment_timestamp ? row?.original?.appointment_timestamp : "",
        rcapname: row?.original?.appointment_cap_name?.props?.children
          ? `${row?.original?.appointment_cap_name?.props?.children[0]}` +
            `,` +
            `${row?.original?.appointment_cap_name?.props?.children[2]}`
          : "",
        online_booking_reference: row?.original?.online_booking_reference
          ? row?.original?.online_booking_reference
          : "",
        onlineBooking: row?.original?.online_booking?.props?.src ? "yes" : "",
        acapname: row?.original?.online_booking_cap_name?.props?.children
          ? `${row?.original?.online_booking_cap_name?.props?.children[0]}` +
            `,` +
            `${row?.original?.online_online_booking_cap_name?.props?.children[2]}`
          : "",
        online_booking_timestamp: row?.original?.online_booking_timestamp ? row?.original?.online_booking_timestamp : "",
        offline_order: row?.original?.offline_order?.props?.src ? "yes" : "",
        offline_order_timestamp: row?.original?.offline_order_timestamp ? row?.original?.offline_order_timestamp : "",
        turn_up: row?.original?.turn_up?.props?.src ? "yes" : "",
        turn_up_timestamp: row?.original?.turn_up_timestamp ? row?.original?.turn_up_timestamp : "",
        test_drive: row?.original?.test_drive?.props?.src ? "yes" : "",
        test_drive_timestamp: row?.original?.test_drive_timestamp ? row?.original?.test_drive_timestamp : "",
        delivery: row?.original?.delivery?.props?.src ? "yes" : "",
        delivery_timestamp: row?.original?.delivery_timestamp ? row?.original?.delivery_timestamp : "",
      };
       
      return data;
    }));
  };

  return (
    <div className="cap-container">

      <MaterialReactTable
        columns={columns}
        data={data}
        enableHiding={true}
        enableFullScreenToggle={false}
        enableDensityToggle={false}
        enablePagination={false}
        enableColumnActions={false}
        enableStickyHeader
        initialState={
          { 
            density: 'compact',
            columnVisibility: { 
              delivery_timestamp: false,
              test_drive_timestamp: false,
              turn_up_timestamp: false,
              offline_order_timestamp: false,
              online_booking_timestamp: false, 
              appointment_timestamp: false,
              registration_timestamp: false,
            } 
          }
        }
        muiLinearProgressProps={({ isTopToolbar }) => ({
          color: 'secondary',
          variant: 'indeterminate', //if you want to show exact progress value
          // value: progress, //value between 0 and 100
          sx: {
            display: isTopToolbar ? 'block' : 'none', //hide bottom progress bar
          },
        })}
        state={{isLoading:isLoading}}
        muiTableContainerProps={{ sx: { minHeight: "300px" } }}
        renderTopToolbarCustomActions={({ table }) => {
          return (
            <div style={{ display: "flex", gap: "0.5rem" }}>
              <p className="fw-5">Referral Buy Car Journey</p>
              {table.getRowModel().rows.length !== 0  ?  <Tooltip title="Download Report">
                  <FileDownloadIcon  className="download-btn" onClick={() =>
              handleExportRows(table.getPrePaginationRowModel().rows)
            } fontSize='medium'/>
              </Tooltip>:""}
            </div>
          );
        }}
        // renderBottomToolbarCustomActions={({table})=>{
        //   return (
        //     <div style={{ display: "flex", gap: "0.5rem" }}>
        //       <p className="bold"> Total Row Count : {table.getPrePaginationRowModel().rows.length}</p>
        //     </div>
        //   );
        // }}
      />
    </div>
  );
};

export default RetailCurrentUserJourney;
