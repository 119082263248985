import React,{useContext, useEffect, useState} from 'react'
import styled from "styled-components";
import { C2BAllUserJourneyContext, 
          B2CRetailAllUserJourneyContext,
          B2CAllRegistrationsContext,
          B2CAllAppointmentsContext,
          B2CAllOnlineBookingsContext,
          B2CAllOrdersContext,
          B2CAllDeliveryContext,
          B2CAllTestDriveContext,
          C2BAllLeadsContext,
          C2BAllOnlineAppointmentsContext,
          C2BAllInspectionsContext,
          C2BAllPurchasesContext,
      } from '../context/user.context';

import { onAllUserB2CRegsitrationsListener,
        onAllUserB2CAppointmentsListener,
        onAllUserB2COnlineBookingsListener,
        onAllUserB2COrdersListener,
        onAllUserB2CTestDriveListener,
        onAllUserB2CDeliveryListener,
        onUserB2CRetailAllUserJourneyListListener,
        onAllUserC2BLeadsListener,
        onAllUserC2BOnlineAppointmentsListener,
        onAllUserC2BInspectionsListener,
        onAllUserC2BPurchasesListener,
        onUserC2BWholesaleAllUserListListener,
    } from "../utils/firebase.utils";

import WholesaleAllUserJourney from '../components/wholesale-user-journey.component';
import RetailUserJourney from '../components/retail-user-journey.component';
import DataCards from '../components/data-cards.components';
import { getAuth } from "firebase/auth";
import Loading from '../components/KPILoader/KPILoader';
import alertIcon from "../assets/images/alert.png"

const Styles = styled.div`
  padding-top:1rem;
  &>.container{
    .data-card-cont{
      display:flex;
      flex-wrap:nowrap;
      justify-content start;
      margin-bottom:1rem;
      .data-card{
        margin-right:1rem;
        margin-bottom:1rem;
        width:25%;
        &:last-of-type{
          margin-right:0;
        }
      }
    }
  .section-2{
    display:flex;
    align-items:start;
    margin-bottom:2rem;
    justify-content: center;
    .table-cont{
      width:70%;
      margin-right:1rem;
    }
    .link-creator{
      width:50%;
    }
  }
  }
  .kpi-header{
    margin-bottom: 10px;
    font-weight: 500;
  }

  .inline-block{
    display: inline-block;
  }

  .info-icon{
    position: relative;
    display: inline-block;
    top: 6px;
    left: 10px;
  }

  .hover-text{
    position: absolute;
    top: 20px;
    left: 70%;
    background-color: white;
    border: 1px solid #E0E0E0;
    border-radius: 2px;
    padding: 10px 15px;
    font-weight: 400;
    font-size: 15px;
    width: max-content;
    line-height: 1.7;
    box-shadow: 0px 2px 2px 0px #00000040;
  }

  .warning-txt{
    float: right;
    font-size: 12px;
    background-color: #D9D9D9;
    padding: 5px 10px 5px 10px;
    border-radius: 20px;
    color: #00205B;
    font-weight: 600;
  }

  .alert-img{
    vertical-align: middle;
    height: 25px;
    padding-right: 7px;
  }

  @media screen and (max-width:1000px){
    &>.container{
      .data-card-cont{
        justify-content:start;
        margin-bottom:0;
        .data-card{
          margin-bottom:1rem;
          margin-right:1rem;
        }
      }
    }
  }
  @media screen and (max-width:850px){
    &>.container{
      .data-card-cont{
        margin-bottom:0;

        flex-wrap:wrap;
        .data-card{
          margin-bottom:1rem;
          &:nth-of-type(even){
            margin-left:10px;
            margin-right:0;
            width: calc( 50% - 10px );
          }
          &:nth-of-type(odd){
            margin-right:10px;
            margin-left:0;
            width: calc( 50% - 10px );
          }
        }
      }
    }
  }
  @media screen and (max-width:600px){
    &>.container{
      .data-card-cont{
        margin-bottom:0;
        justify-content:space-between;
        .data-card{
          margin-bottom:1rem;
          margin-left:0;
          margin-right:0;
        }
      }
      .section-2{
        flex-direction:column-reverse;
        .table-cont{
          width:100%;
          margin-right:0rem;
        }
        .link-creator{
          width:100%;
          margin-left:0rem;
          margin-bottom:1.5rem;
        }
      }
    }
  }
  @media screen and (max-width:410px){
    &>.container{
      .data-card-cont{
        margin-bottom:0;
        flex-direction:column;
        flex-wrap:nowrap;
        justify-content:start;
        align-items:stretch;
        .data-card{
          margin-bottom:1rem;
          margin-left:0!important;
          margin-right:0!important;
          width:100%!important;
          text-align:Center;
        }
      }
  }
  .m-t-20{
    margin-top: 20px;
  }
`;


const AdminView = () => {
  
  const {userB2CAllUserJourney} = useContext(B2CRetailAllUserJourneyContext);
  const {userC2BAllUserJourney} = useContext(C2BAllUserJourneyContext);
  const {allUserB2CRegistrations, setAllUserB2CRegistrations} = useContext(B2CAllRegistrationsContext);
  const {allUserB2CAppointments, setAllUserB2CAppointments} = useContext(B2CAllAppointmentsContext);
  const {allUserB2COnlineBookings, setAllUserB2COnlineBookings} = useContext(B2CAllOnlineBookingsContext);
  const {allUserB2COrders, setAllUserB2COrders} = useContext(B2CAllOrdersContext);
  const {allUserB2CDelivery, setAllUserB2CDelivery} = useContext(B2CAllDeliveryContext);
  const {allUserB2CTestDrive, setAllUserB2CTestDrive} = useContext(B2CAllTestDriveContext);
  const { setUserB2CAllUserJourneyList} = useContext(B2CRetailAllUserJourneyContext);
  const {allUserC2BLeads, setAllUserC2BLeads} = useContext(C2BAllLeadsContext);
  const {allUserC2BOnlineAppointments, setAllUserC2BOnlineAppointments} = useContext(C2BAllOnlineAppointmentsContext);
  const {allUserC2BInspections, setAllUserC2BInspections} = useContext(C2BAllInspectionsContext);
  const {allUserC2BPurchases, setAllUserC2BPurchases} = useContext(C2BAllPurchasesContext);
  const {setUserC2BAllUserJourney} = useContext(C2BAllUserJourneyContext);
  const [isRetailLoading, setIsRetailLoading]=useState(true)
  const [isWholesaleLoading, setIsWholesaleLoading]=useState(true)

  const getAdminDetailsByUid = ()=>{
    const auth = getAuth();
    const user = auth.currentUser;
  
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      // ...

      // B2C
      onAllUserB2CRegsitrationsListener(user.uid, (res)=>{
          setAllUserB2CRegistrations(res)
      })

      onAllUserB2CAppointmentsListener(user.uid, (res)=>{
          setAllUserB2CAppointments(res)
      })

      onAllUserB2COnlineBookingsListener(user.uid, (res)=>{
          setAllUserB2COnlineBookings(res)
      })

      onAllUserB2COrdersListener(user.uid, (res)=>{
          setAllUserB2COrders(res)
      })

      onAllUserB2CTestDriveListener(user.id, (res)=>{
          setAllUserB2CTestDrive(res)
      })

      onAllUserB2CDeliveryListener(user.id, (res)=>{
          setAllUserB2CDelivery(res)
      })
      onUserB2CRetailAllUserJourneyListListener(user.uid, (res)=>{
        setUserB2CAllUserJourneyList(res)
        setIsRetailLoading(false)
      })


      // C2B
      onAllUserC2BLeadsListener(user.uid, (res)=>{
          setAllUserC2BLeads(res)
      })

      onAllUserC2BOnlineAppointmentsListener(user.uid, (res)=>{
          setAllUserC2BOnlineAppointments(res)
      })

      onAllUserC2BInspectionsListener(user.uid, (res)=>{
          setAllUserC2BInspections(res)
      })

      onAllUserC2BPurchasesListener(user.uid, (res)=>{
          setAllUserC2BPurchases(res)
      })
      onUserC2BWholesaleAllUserListListener(user.uid, (req)=>{
        setUserC2BAllUserJourney(req)
        setIsWholesaleLoading(false)
      })
                  
  }
}

  useEffect(() => {
    getAdminDetailsByUid()
  },[])

  return (
    <Styles>

      <div className='container'>
          <p className='kpi-header inline-block'>Buy Car</p>
          <div className='data-card-cont'>
            <DataCards name={"Registration"} value={allUserB2CRegistrations}/>
            <DataCards name={"Appointment"} value={allUserB2CAppointments}/>
            <DataCards name={"Online Order"} value={allUserB2COnlineBookings}/>
            <DataCards name={"Offline Order"} value={allUserB2COrders}/>
            <DataCards name={"Test Drive"} value={allUserB2CTestDrive}/>
            <DataCards name={"Delivery"} value={allUserB2CDelivery}/>
          </div>
      </div>

     <div className='container'>
      <div className='m-b-10'>
          <p className='kpi-header inline-block'>Sell Car</p>
          <div className='warning-txt'>
            <img className="alert-img" src={alertIcon} alt="Alert icon"/>
            Inspection & Purchase data will be updated on or before 15th of each month.
          </div>
        </div>

        <div className='data-card-cont'>
          <DataCards name={"Lead"} value={allUserC2BLeads}/>
          <DataCards name={"Online Appointment"} value={allUserC2BOnlineAppointments}/>
          <DataCards name={"Inspection"} value={allUserC2BInspections}/>
          <DataCards name={"Purchase"} value={allUserC2BPurchases}/>
        </div>
     </div>



      <div className='container'>
        
        <div className="userJourney m-t-20 m-b-30" id="retail-all-user-journey">
            <RetailUserJourney
                data={userB2CAllUserJourney}
                isLoading={isRetailLoading}
            />
        </div>

        <div id="wholesale-all-user-journey" className="m-t-20">
          <WholesaleAllUserJourney 
            data={userC2BAllUserJourney}
            isLoading={isWholesaleLoading}
          />
        </div>
      </div>
    </Styles>
  )
}

export default AdminView